<template>
    
    <div>
      <svg 
        v-if="started"
        ref="workspace"
        id="workspace"
        version="1.1" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlns:xlink="http://www.w3.org/1999/xlink" 
        x="0" 
        y="0"
        :width="width"
        :height="height"
        :viewBox="viewBox"
        xml:space="preserve"
        v-html="exerciseSvg"
        @mousedown="handleMouseDown"
        @mousemove="handleMouseMove"
        @mouseup="handleMouseUp"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd"      
      >
      </svg>

      <v-container 
        v-if="!started"
        fill-height 
        fluid
      >
        <v-row align="center"
          justify="center"
        >
          <v-col>
            <v-flex d-flex>
              <v-layout wrap> 
                <v-flex 
                  md4
                >        
                </v-flex>
                <v-flex 
                  md4
                  pa-2         
                >
                  <v-card style="background: #ffffff;">
                    <v-card-title>
                      <v-img
                        alt="Metricforms"
                        class="shrink mr-2"
                        contain
                        src="@/assets/logo.png"
                      ></v-img>
                    </v-card-title>                       
                    <v-card-text>
                      <v-progress-circular
                        v-if="!loaded"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>                        
                      {{ exerciseTitle }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn 
                        v-if="loaded"
                        primary block
                        @click="start()"
                      >
                        Start
                      </v-btn>
                    </v-card-actions>                
                  </v-card>
                </v-flex>
                <v-flex 
                  md4
                >        
                </v-flex>
              </v-layout>
            </v-flex>
          </v-col>
        </v-row>
      </v-container>

    </div>
</template>

<script>
  import Synergy from '@/libs/Synergy'
  import keyboardUtils from '@/utils/keyboardUtils'
  import { RepositoryFactory } from '@/repositories/RepositoryFactory'

  const CourseRepository = RepositoryFactory.get('course');

  //import { RepositoryFactory } from '@/repositories/RepositoryFactory'

  //const CourseRepository = RepositoryFactory.get('course');

  export default {
    name: 'ExercisePlayer',

    data: () => ({
      started: false,
      question: {},
      symbols: {},
      exerciseSvg: '', //'<rect x="0" y="0" width="1280" height="768" style="stroke: none; fill: #dddddd;"></rect>',
      widgetIndex: -1,
      completed: false,
      correct: true,
      total: 0,
      helpBox: [],
      helpBoxIndex: -1,
      marks: 0,   
      usedHelp: false,   
      help: {
        x: 0,
        y: 0,
        visible: false,
        minX: 0,
        minY: 0,
        maxX: 0,
        maxY: 0,
        hasItems: false
      }, 
      keyboard: {
        type: 'alphanumeric1',
        visible: false,
        x: 160,
        y: 400,
        font: 'Arial' 
      },    
      audioQueue: [],
      audioQueueSaved: [],
      currentAudioClip: null,
      locale: '',
      phrases: {},
      lastTouchEvent: null,
      renderLandscape: true,
      exercise: null,
      exerciseTitle: 'Loading exercise, please wait...',
      loaded: false,
      questionsCompleted: 0,
      cursorVisible: true,
      cursorTimeout: null,
    }),

    props: {    

      landscape: {
        type: Boolean,
        default: true,
        required: true
      },

      width: {
        type: Number,
        default: 1280,
        required: true        
      }, 

      height: {
        type: Number,
        default: 768,
        required: true        
      },       

      totalQuestions: {
        type: Number,
        default: 0,       
      }, 

    },

    computed: {

      viewBox: function () {
        if (this.renderLandscape) {
          return '0 0 1280 768';          
        } else {
          return '0 0 768 1280';      
        }        
      },

      workspaceWidth: function () {
        if (this.renderLandscape) {
          return 1280;          
        } else {
          return 768;
        }        
      },

      workspaceHeight: function () {
        if (this.renderLandscape) {
          return 768;          
        } else {
          return 1280;
        }        
      },

    },

    created() {
      window.addEventListener('keypress', this.keyPress);
      window.addEventListener('keydown', this.keyDown);
    },

    destroyed() {
      window.removeEventListener('keypress', this.keyPress);
      window.removeEventListener('keydown', this.keyDown);
    },

    mounted() {  
      this.$emit('loadExercise');
    },

    unmounted() {
      alert('dd');
    },

    methods: {

      start() {
        this.started = true;
        this.nextQuestion();
      },

      clear() {

        this.clearAudio();

        this.question = {};
        this.symbols = {};
        this.exerciseSvg = '';
        this.widgetIndex = -1;
        this.completed = false;
        this.correct = true;
        this.total = 0;
        this.marks = 0;
        this.usedHelp = false;
        this.help = {
          x: 0,
          y: 0,
          visible: false,
          minX: 0,
          minY: 0,
          maxX: 0,
          maxY: 0,
          hasItems: false
        };
        this.keyboard = {
          type: 'alphanumeric1',
          visible: false,
          x: 160,
          y: 400,
          font: 'Arial' 
        };        
        this.locale = '';
        this.phrases = {};
      },

      clearAudio() {
        // stop current audio 
        this.audioQueue = [];
        this.audioQueueSaved = [];
        if (this.currentAudioClip != null) {
          this.currentAudioClip.pause();
          this.currentAudioClip = null;
        }

        this.$emit('clearedAudio');        
      },

      async deviceDown_dragText(widget, x, y) {
        var item;		
        var i;
        var width;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var align;
        var compX;

        if (!Object.prototype.hasOwnProperty.call(widget, 'state')) {
          widget['state'] = {};
          widget.state['selectedindex'] = -1;
          widget.state['userinput'] = -1;
        }

        ctx.font = widget.fontsize + 'px Arial';

        i = 0;
        while ((i < widget.items.length) && (widget.state.selectedindex < 0)) {
          item = widget.items[i];
          
          align = 'left';
          if (Object.prototype.hasOwnProperty.call(item, 'align')) {
            align = item.align;
          }
          
          width = ctx.measureText(item.text).width;

          compX = item.x;
          switch (align) {
            case 'right':
              compX = compX - width;
              break;  
            case 'center':
              compX = compX - (width / 2);
              break;                               
          }
          
          if ((x >= compX) && (x < (compX + width)) && (y >= (item.y - widget.fontsize * 0.8)) && (y < (item.y + widget.fontsize * 0.2))) {
            widget.state.selectedindex = i;
            switch (align) {
              case 'left':
                item['dragx'] = x - (width / 2);
                break;
              case 'right':
                item['dragx'] = x + (width / 2);
                break;  
              case 'center':
                item['dragx'] = x;
                break;                               
            }
            
            item['dragy'] = y + widget.fontsize * 0.2;
          } 
          i++;
        }

      },

      deviceDown_lineinputs(widget, x, y) {

        var item;

        if (!Object.prototype.hasOwnProperty.call(widget, 'userinput')) {
          widget['userinput'] = [];
        }

        widget['fromindex'] = -1;
        widget['fromend'] = false;
        widget['tox'] = -1;
        widget['toy'] = -1;

        var zoneWidthMid = widget.zonewidth / 2;
        var zoneHeightMid = widget.zonewidth / 2;

        var i = 0;
        while (i < widget.items.length) {
          item = widget.items[i];
          if ((x >= (item.x1 - zoneWidthMid)) && (x <= (item.x1 + zoneWidthMid)) &&
            (y >= (item.y1 - zoneHeightMid)) && (y <= (item.y1 + zoneHeightMid))) {
              widget['fromindex'] = i;
              widget['fromend'] = false;
              widget['tox'] = x;
              widget['toy'] = y;

          } else if ((x >= (item.x2 - zoneWidthMid)) && (x <= (item.x2 + zoneWidthMid)) &&
            (y >= (item.y2 - zoneHeightMid)) && (y <= (item.y2 + zoneHeightMid))) {
              widget['fromindex'] = i;
              widget['fromend'] = true;
              widget['tox'] = x;
              widget['toy'] = y;

          }            
          i++;
        }

        if (widget.fromindex >= 0) {
          
          i = 0;
          while (i < widget.userinput.length) {

            if ((widget.userinput[i].from == widget.fromindex) && !widget.fromend) {
              widget.userinput.splice(i, 1);
            } else if ((widget.userinput[i].to == widget.fromindex) && widget.fromend) {
              widget.userinput.splice(i, 1);              
            } else {
              i++;
            }            
          }

          var el = document.getElementById(widget.id);
          el.setAttribute('x1', x);
          el.setAttribute('y1', y);
          el.setAttribute('x2', x);
          el.setAttribute('y2', y);

        }

        //this.renderQuestion();

      },

      deviceDown_wordblock(widget, x, y) {
        var item;		
        var font = 'Arial';
        var fontSize = 32;
        var cWidth = 0;
        var cHeight = 0;
        var wWidth = 0;
        var tHeight = 0;
        var itemX = 0;
        var itemY = 0;
        var tmpInt;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        if (!Object.prototype.hasOwnProperty.call(widget, 'state')) {
          widget['state'] = {};
          widget.state['startcol'] = -1;
          widget.state['startrow'] = -1;
          widget.state['endcol'] = -1;
          widget.state['endrow'] = -1;
          widget.state['userinput'] = [];
        }

        item = widget.letters;

        if (item.length > 0) {
            
          if (Object.prototype.hasOwnProperty.call(widget, 'font')) {			
            font = widget.font;
          }		
          fontSize = parseInt(widget.fontsize);		
          ctx.font = fontSize + 'px ' + font;		
        
          wWidth = parseInt(ctx.measureText('W').width);
          tHeight = fontSize * 1.45;
        
          cWidth = item[0].length * wWidth;
          cHeight = item.length * tHeight;

          itemX = widget.x;
          itemY = widget.y;

          if ((x >= itemX) && (x < (itemX + cWidth)) && (y >= (itemY)) && (y < (itemY + cHeight))) {
            
            tmpInt = Math.floor((x - itemX) / wWidth);
            widget.state['startcol'] = tmpInt;

            tmpInt = Math.floor((y - itemY) / tHeight);
            widget.state['startrow'] = tmpInt;

            widget.state['endcol'] = widget.state['startcol']; 
            widget.state['endrow'] = widget.state['startrow']; 
            
          }

        }

      },

      deviceMove_dragText(widget, x, y) {
        var item;		
        var width;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        if (Object.prototype.hasOwnProperty.call(widget, 'state')) {
          
          if (widget.state.selectedindex >= 0) {
            ctx.font = widget.fontsize + 'px Arial';			
            item = widget.items[widget.state.selectedindex];
            width = ctx.measureText(item.text).width;
            
            item.dragx = x - width / 2;
            item.dragy = y + widget.fontsize * 0.2;	
            var el = document.getElementById(item.id);
            el.setAttribute('x', item.dragx);
            el.setAttribute('y', item.dragy);		
          }
          
        }
      },

      deviceMove_lineinputs(widget, x, y) {
        if (widget.fromindex >= 0) {
          widget.tox = x;
          widget.toy = y;
          var el = document.getElementById(widget.id);
          el.setAttribute('x2', widget.tox);
          el.setAttribute('y2', widget.toy);
        }
       // this.renderQuestion();
      },

      deviceMove_wordblock(widget, x, y) {
        var item;		
        var font = 'Arial';
        var fontSize = 32;
        var cWidth = 0;
        var cHeight = 0;
        var wWidth = 0;
        var tHeight = 0;
        var itemX = 0;
        var itemY = 0;
        var tmpInt;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        if (Object.prototype.hasOwnProperty.call(widget, 'state')) {
          
          item = widget.letters;

          if (item.length > 0) {
              
            if (Object.prototype.hasOwnProperty.call(widget, 'font')) {			
              font = widget.font;
            }		
            fontSize = parseInt(widget.fontsize);		
            ctx.font = fontSize + 'px ' + font;		
          
            wWidth = parseInt(ctx.measureText('W').width);
            tHeight = fontSize * 1.45;
          
            cWidth = item[0].length * wWidth;
            cHeight = item.length * tHeight;

            itemX = widget.x;
            itemY = widget.y;

            if ((x >= itemX) && (x < (itemX + cWidth)) && (y >= (itemY)) && (y < (itemY + cHeight))) {
              
              tmpInt = Math.floor((x - itemX) / wWidth);
              widget.state['endcol'] = tmpInt;

              tmpInt = Math.floor((y - itemY) / tHeight);
              widget.state['endrow'] = tmpInt;
              
            }

            // highlight blocks

            this.highlightWordblock(widget);	

          }
          
        }
      },

      deviceUp_dragText(widget, x, y) {
        var item;		
        var width;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var align;

        if (Object.prototype.hasOwnProperty.call(widget, 'state')) {

          if (widget.state.selectedindex >= 0) {
            ctx.font = widget.fontsize + 'px Arial';			
            item = widget.items[widget.state.selectedindex];

            align = 'left';
            if (Object.prototype.hasOwnProperty.call(item, 'align')) {
              align = item.align;
            }

            width = ctx.measureText(item.text).width;
            if ((x >= widget.dropzone.x) && (x < (widget.dropzone.x + widget.dropzone.width)) && 
              (y >= widget.dropzone.y) && (y < (widget.dropzone.y + widget.dropzone.height))) {
              
              switch (align) {
                case 'left':
                  item.dragx = x - width / 2;
                  break;
                case 'right':
                  item.dragx = x + (width / 2);
                  break;  
                case 'center':
                  item.dragx = x;
                  break;                               
              }  

              item.dragy = y + widget.fontsize * 0.2;
              
              widget.userinput = widget.state.selectedindex;
              
              this.nextWidget();

              this.renderQuestion();

            } else {

              switch (align) {
                case 'left':
                  item.dragx = item.x;
                  break;
                case 'right':
                  item.dragx = item.x - width;
                  break;  
                case 'center':
                  item.dragx = item.x - (width / 2);
                  break;                               
              }

              item.dragy = item.y;		
              var el = document.getElementById(item.id);
              el.setAttribute('x', item.dragx);
              el.setAttribute('y', item.dragy);	              
            }
            
            widget.state.selectedindex = -1;
          }
        
        }

      },

      deviceUp_lineinputs(widget, x, y) {

        var item;
        var toIndex = -1;

        if (!Object.prototype.hasOwnProperty.call(widget, 'userinput')) {
          widget['userinput'] = [];
        }

        var zoneWidthMid = widget.zonewidth / 2;
        var zoneHeightMid = widget.zonewidth / 2;

        var i = 0;
        while ((i < widget.items.length) && (toIndex < 0)) {
          item = widget.items[i];

          if ((x >= (item.x2 - zoneWidthMid)) && (x <= (item.x2 + zoneWidthMid)) &&
            (y >= (item.y2 - zoneHeightMid)) && (y <= (item.y2 + zoneHeightMid)) && !widget.fromend) {

              toIndex = i;
              if (widget.fromindex >= 0) {
                widget.userinput.push({from:widget.fromindex,to:toIndex});
              }

          } else if ((x >= (item.x1 - zoneWidthMid)) && (x <= (item.x1 + zoneWidthMid)) &&
            (y >= (item.y1 - zoneHeightMid)) && (y <= (item.y1 + zoneHeightMid)) && widget.fromend) {

              toIndex = i;
              if (widget.fromindex >= 0) {
                widget.userinput.push({from:toIndex,to:widget.fromindex});
              }

          }            
          i++;
        }

        if (toIndex >= 0) {

          i = 0;
          while (i < widget.userinput.length - 1) {

            if ((widget.userinput[i].to == toIndex) && !widget.fromend) {
              widget.userinput.splice(i, 1);
            } else if ((widget.userinput[i].from == toIndex) && widget.fromend) {
              widget.userinput.splice(i, 1);
            } else {
              i++;
            }            
          }

        }

        widget['fromindex'] = -1;
        widget['fromend'] = false;
        widget['tox'] = -1;
        widget['toy'] = -1;

        var el = document.getElementById(widget.id);
        el.setAttribute('x1', -100);
        el.setAttribute('y1', -100);
        el.setAttribute('x2', -50);
        el.setAttribute('y2', -50);

        if (widget.userinput.length >= widget.items.length) {
          this.nextWidget();
        } else {
          this.renderQuestion();
        }

      },

      deviceUp_textMC(widget, x, y) { // eslint-disable-line
        var font = 'Arial';
        var fontSize;		
        var itemIndex;
        var x1;
        var y1;
        var x2;
        var y2;
        var i;
        var tmpStr;
        var wantedInputs;
        var givenInputs;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var item;

        itemIndex = -1;
        
        if (Object.prototype.hasOwnProperty.call(widget, 'font')) {			
          font = widget.font;
        }		
        fontSize = parseInt(widget.fontsize);		
        ctx.font = fontSize + 'px ' + font;	

        x1 = parseInt(widget.x);
        y1 = parseInt(widget.y);
        
        i = 0;
        while ((i < widget.items.length) && (itemIndex < 0)) {
          item = widget.items[i];

          tmpStr = String.fromCharCode(65 + i) + '. ' + item.text;

          x2 = x1 + ctx.measureText(tmpStr).width;
          y2 = y1 + fontSize + 10;
          
          if ((x >= x1) && (x < x2) && (y >= (y1 - fontSize * 0.8)) && (y < (y2 - fontSize * 0.8))) {
            itemIndex = i;
          }
          
          y1 = y2;
          
          i++;
        }
        
        if (itemIndex >= 0) {
          if (Object.prototype.hasOwnProperty.call(widget, 'userinput')) {
            widget.userinput[itemIndex] = !widget.userinput[itemIndex];
          } else {
            widget['userinput'] = [];
            i = 0;
            while (i < widget.items.length) {
              widget.userinput.push(false);
              i++;
            }
            widget.userinput[itemIndex] = true;				
          }
          
          wantedInputs = 0;
          givenInputs = 0;
          i = 0;
          while (i < widget.items.length) {	
            if (widget.items[i].correct) {
              wantedInputs++;
            }
            if (widget.userinput[i]) {
              givenInputs++;
            }			
            i++;
          }		
          
          if (givenInputs >= wantedInputs) {
            this.nextWidget();
          }			
          
        }

      },

      deviceUp_wordblock(widget, x, y) { // eslint-disable-line
        var item;		
        var font = 'Arial';
        var fontSize = 32;
        var cWidth = 0;
        var cHeight = 0;
        var wWidth = 0;
        var tHeight = 0;
        var itemX = 0;
        var itemY = 0;
        var startCol;
        var startRow;
        var endCol;
        var endRow;
        var tmpInt;
        var word;
        var row;
        var i;
        var wordIndex;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        if (Object.prototype.hasOwnProperty.call(widget, 'state')) {
          
          item = widget.letters;

          if (item.length > 0) {
              
            if (Object.prototype.hasOwnProperty.call(widget, 'font')) {			
              font = widget.font;
            }		
            fontSize = parseInt(widget.fontsize);		
            ctx.font = fontSize + 'px ' + font;		
          
            wWidth = parseInt(ctx.measureText('W').width);
            tHeight = fontSize * 1.45;
          
            cWidth = item[0].length * wWidth;
            cHeight = item.length * tHeight;

            itemX = widget.x;
            itemY = widget.y;

            if ((x >= itemX) && (x < (itemX + cWidth)) && (y >= (itemY)) && (y < (itemY + cHeight))) {
              
              tmpInt = Math.floor((x - itemX) / wWidth);
              widget.state['endcol'] = tmpInt;

              tmpInt = Math.floor((y - itemY) / tHeight);
              widget.state['endrow'] = tmpInt;
              
            }

          }

          startCol = widget.state.startcol;
          startRow = widget.state.startrow;
          endCol = widget.state.endcol;
          endRow = widget.state.endrow;

          if (startCol > endCol) {
            tmpInt = startCol;
            startCol = endCol;
            endCol = tmpInt;
          }

          if (startRow > endRow) {
            tmpInt = startRow;
            startRow = endRow;
            endRow = tmpInt;
          }
          
          word = '';

          if (startRow == endRow) {
            
            if (startRow < item.length) {
              row = item[startRow];
              i = startCol;
              while (i <= endCol) {
                word = word + row[i];
                i++;
              }
            }

          } else if (startCol == endCol) {
            
            i = startRow;
            while (i <= endRow) {
              if (i < item.length) {
                row = item[i];
                if (startCol < row.length) {
                  word = word + row[startCol];
                }
              }
              i++;
            }

          }

          wordIndex = -1;
          if (word != '') {
            i = 0;
            while ((i < widget.words.length) && (wordIndex < 0)) {
              if (widget.words[i] == word) {
                wordIndex = i;
              }
              i++;
            }
          }

          if (wordIndex >= 0) {
            if (!widget.state['userinput'].includes(wordIndex)) {
              widget.state['userinput'].push(wordIndex);
            }

            if (widget.state['userinput'].length >= widget.words.length) {
              this.nextWidget();
            }
          }

          widget.state['startcol'] = -1;
          widget.state['startrow'] = -1;
          widget.state['endcol'] = -1;
          widget.state['endrow'] = -1;
          
          this.highlightWordblock(widget);	

        }
      },

      flashCursor() {
        var t = this;
        this.cursorVisible = !this.cursorVisible;
        var el = document.getElementById('textcursor');
        if (el) {
          if (this.cursorVisible) {
            el.style.display = 'block';
          } else {
            el.style.display = 'none';
          }
          if (!this.completed) {
            setTimeout(t.flashCursor, 500);
          }
        }
      },

      getImageDimensions(base64) {
        return new Promise (function (resolved, rejected) { // eslint-disable-line
          var i = new Image()
          i.onload = function(){
            resolved({width: i.width, height: i.height})
          };
          i.src = base64
        })
      },

      getProperty(widget, prop, defaultValue) {
        let result;
        let key;
        if (Object.prototype.hasOwnProperty.call(widget, prop)) {
          result = widget[prop];
          for (key in this.symbols) {
            result = this.replaceAllCI(result, '{' + key + '}', this.symbols[key]);
          }	          
        } else {
          result = defaultValue;
        }
        return result;
      },

      handleMouseDown(e) {
        if (!this.isTouchScreenDevice()) {
          var scale = this.$refs.workspace.clientWidth / this.workspaceWidth;
          var x = (e.clientX - this.$refs.workspace.getBoundingClientRect().left) / scale;
          var y = (e.clientY - this.$refs.workspace.getBoundingClientRect().top) / scale;        
          this.handleDeviceDown(x, y); 
        }
      },

      handleMouseMove(e) {
        if (!this.isTouchScreenDevice()) {
          var scale = this.$refs.workspace.clientWidth / this.workspaceWidth;
          var x = (e.clientX - this.$refs.workspace.getBoundingClientRect().left) / scale;
          var y = (e.clientY - this.$refs.workspace.getBoundingClientRect().top) / scale;
          this.handleDeviceMove(x, y); 
        }
      },

      handleMouseUp(e) {
        if (!this.isTouchScreenDevice()) {
          var scale = this.$refs.workspace.clientWidth / this.workspaceWidth;
          var x = (e.clientX - this.$refs.workspace.getBoundingClientRect().left) / scale;
          var y = (e.clientY - this.$refs.workspace.getBoundingClientRect().top) / scale;
          this.handleDeviceUp(x, y); 
        }  
      },

      handleTouchStart(e) {        
        var scale = this.$refs.workspace.clientWidth / this.workspaceWidth;
        var x = (e.targetTouches[0].clientX - this.$refs.workspace.getBoundingClientRect().left) / scale;
        var y = (e.targetTouches[0].clientY - this.$refs.workspace.getBoundingClientRect().top) / scale; 
        this.lastTouchEvent = e;            
        this.handleDeviceDown(x, y);
        e.preventDefault();      
      },

      handleTouchMove(e) {
        var scale = this.$refs.workspace.clientWidth / this.workspaceWidth;
        var x = (e.targetTouches[0].clientX - this.$refs.workspace.getBoundingClientRect().left) / scale;
        var y = (e.targetTouches[0].clientY - this.$refs.workspace.getBoundingClientRect().top) / scale;
        this.lastTouchEvent = e;
        e.preventDefault();
        this.handleDeviceMove(x, y);     
      },

      handleTouchEnd() {
        var scale = this.$refs.workspace.clientWidth / this.workspaceWidth;        
        var x = (this.lastTouchEvent.targetTouches[0].clientX - this.$refs.workspace.getBoundingClientRect().left) / scale;
        var y = (this.lastTouchEvent.targetTouches[0].clientY - this.$refs.workspace.getBoundingClientRect().top) / scale;     
        this.lastTouchEvent = null;      
        this.handleDeviceUp(x, y);    
      },

      handleDeviceDown(x, y) {
        if (!this.completed) {
          
          if (this.help.visible) {
            this.help.visible = false;
            this.renderQuestion();
          } else {
          
            var key = '';
            if (this.keyboard.visible) {
              key = this.keyAt(x, y);
              switch (key) {
                case 'BACKSPACE':
                  this.handleKey(8);
                  break;
                case 'CASE':
                  this.keyboard.uppercase = !this.keyboard.uppercase;
                  this.renderQuestion();
                  break;
                default:
                  this.handleKey(key.charCodeAt(0));
                  break;
              }
            }
            
            if (key == '') {
              if (this.widgetIndex >= 0) {
                var widget = this.question.widgets[this.widgetIndex];
                switch (widget.type) {
                  case 'dragtext':
                    this.deviceDown_dragText(widget, x, y);
                    break;	
                  case 'lineinputs':
                    this.deviceDown_lineinputs(widget, x, y);
                    break;	                    			
                  case 'wordblock':
                    this.deviceDown_wordblock(widget, x, y);
                    break;							
                }
              }
            }
            
          }

        } else {
          this.nextQuestion();
        }
      },

      handleDeviceMove(x, y) { // eslint-disable-line
        if (!this.completed) {
          if (this.widgetIndex >= 0) {
            var widget = this.question.widgets[this.widgetIndex];
            switch (widget.type) {
              case 'dragtext':
                this.deviceMove_dragText(widget, x, y);
                break;
              case 'lineinputs':
                this.deviceMove_lineinputs(widget, x, y);
                break;                
              case 'wordblock':
                this.deviceMove_wordblock(widget, x, y);
                break;					
            }
          }
        }
      },

      handleDeviceUp(x, y) {
        if (!this.completed) {
          if (this.widgetIndex >= 0) {
            var widget = this.question.widgets[this.widgetIndex];
            switch (widget.type) {
              case 'dragtext':
                this.deviceUp_dragText(widget, x, y);
                break;
              case 'lineinputs':
                this.deviceUp_lineinputs(widget, x, y);
                break;                
              case 'textmc':
                this.deviceUp_textMC(widget, x, y);
                break;	
              case 'wordblock':
                this.deviceUp_wordblock(widget, x, y);
                break;					
            }
          }          
        }
      },

      handleKey(key) {
        if (this.started) {
          var widget;
          if (this.completed) {
            if ((key == 32) || (key == 13)) {
              this.nextQuestion();	            
            }							
          } else {
            if ((key > 32) || (key == 8)) {
              widget = this.question.widgets[this.widgetIndex]; 
              switch (widget.type) {
                case 'textline':
                  this.handleKey_textLine(widget, key);
                  break;
                case 'textmc':
                  this.handleKey_textMC(widget, key);
                  break;					
              }          
              this.renderQuestion();
            } 
          }
        }
      },

      handleKey_textLine(widget, key) {
        var i;
        var foundItem;
        var item;
        var isInput;
        var hasMoreInput;
        
        if (key == 8) { // backspace?
          
          i = widget.items.length - 1;
          foundItem = false;
          while ((i >= 0) && !foundItem) {
            item = widget.items[i];

            isInput = false;
            if (Object.prototype.hasOwnProperty.call(item, 'input')) {
              isInput = item.input;
            }
            if (isInput) {
              if (!Object.prototype.hasOwnProperty.call(item, 'userinput')) {
                item['userinput'] = '';
              }
              if (item.userinput.length > 0) {						
                item.userinput = item.userinput.substring(0, item.userinput.length - 1);						
                foundItem = true;
              }
            }

            i--;
          }
          if (!foundItem) {
            this.prevWidget();
          }
          
        } else if (key > 32) {
        
          i = 0;
          foundItem = false;
          hasMoreInput = false;
          while ((i < widget.items.length) && !hasMoreInput) {
            item = widget.items[i];
            if (foundItem) {
              if (Object.prototype.hasOwnProperty.call(item, 'input')) {
                if (item.input) {
                  hasMoreInput = true;
                }
              }				
            } else {
              isInput = false;
              if (Object.prototype.hasOwnProperty.call(item, 'input')) {
                isInput = item.input;
              }
              if (isInput) {
                if (!Object.prototype.hasOwnProperty.call(item, 'userinput')) {
                  item['userinput'] = '';
                }
                
                if (!this.inputMatchLen(item.userinput, item.text)) {
                //if (item.userinput.length < item.text.length) {
                  foundItem = true;
                  item.userinput = item.userinput + String.fromCharCode(key);
                  if (!this.inputMatchLen(item.userinput, item.text)) {
                  //if (item.userinput.length < item.text.length) {
                    hasMoreInput = true;
                  }
                }
              }
            }
            i++;
          }
          if (!hasMoreInput) {
            this.nextWidget();
          }
        
        }
        
      },

      handleKey_textMC(widget, key) {
        var i;
        
        if (key == 8) { // backspace
          if (Object.prototype.hasOwnProperty.call(widget, 'userinput')) {
            i = 0;
            while (i < widget.userinput.length) {
              widget.userinput[i] = false;
              i++;
            }
          }
          this.prevWidget();
        } 
        
      },

      highlightWordblock(widget) {

        var i, j;
        var words = [];		
        var word;
        var startCol;
        var item = widget.letters;
        var row;
        var foundWord;
        var tmpCol;
        var kStep;
        var startRow;
        var endCol;
        var endRow;
        var color;
        var tmpInt;
        var el;

        // clear highlights

        j = 0;
        while (j < item.length) {
          row = item[j];
          i = 0;
          while (i < row.length) {

            el = document.getElementById('block_' + i + '_' + j);
            el.setAttribute('fill', '#ffffff');
            el.setAttribute('fill-opacity', '0');  

            i++;
          }
          j++;
        }

        if (this.help.visible) {
          words = widget.words;
        } else if (Object.prototype.hasOwnProperty.call(widget, 'state')) {
          i = 0;
          while (i < widget.state.userinput.length) {
            words.push(widget.words[widget.state.userinput[i]]);
            i++;
          }
        }

        i = 0;
        while (i < words.length) {
    
          word = words[i];
          startCol = -1;
    
          // search rows
    
          j = 0;
          while ((j < item.length) && (startCol < 0)) {
            row = item[j];
            
            foundWord = false;
            tmpCol = 0;

            while (((tmpCol + word.length - 1) < row.length) && !foundWord) {

              kStep = 0;
              foundWord = true;
              while ((kStep < word.length) && foundWord) {
                if ((tmpCol + kStep) < row.length) {
                  foundWord = (row[tmpCol + kStep] == word[kStep]);								
                } else {
                  foundWord = false;
                }
                kStep++;
              }
              
              if (!foundWord) {
                tmpCol = tmpCol + 1;
              }
              
            }
      
            if (foundWord) {
              startCol = tmpCol;
              startRow = j;
              endCol = tmpCol + word.length - 1;
              endRow = j;
            }
      
            j++;
          }
    
          // search columns
    
          if (startCol < 0) {

            j = 0;
            while (((j + word.length - 1) < item.length) && (startCol < 0)) {
              row = item[j];
              
              foundWord = false;
              tmpCol = 0;
              while ((tmpCol < row.length) && !foundWord) {
              
                kStep = 0;
                foundWord = true;
                while ((kStep < word.length) && foundWord) {
                  row = item[j + kStep];
                  if (tmpCol < row.length) {
                    foundWord = (row[tmpCol] == word[kStep]);
                  } else {
                    foundWord = false;
                  }
                  kStep++;
                }
                
                if (!foundWord) {
                  tmpCol = tmpCol + 1;
                }
              }
              
              if (foundWord) {
                startCol = tmpCol;
                startRow = j;
                endCol = tmpCol;
                endRow = j + word.length - 1;
              }
              
              j++;
            }

          }
    
          if (startCol >= 0) {

            switch (i % 4) {
              case 0:
                color = '#007700';
                break;
              case 1:
                color = '#770000';					
                break;
              case 2:
                color = '#000099';	
                break;
              case 3:
                color = '#999900';
                break;
            }

            if (startCol == endCol) {
              j = startRow;
              while (j <= endRow) {
                el = document.getElementById('block_' + startCol + '_' + j);
                el.setAttribute('fill', color);
                el.setAttribute('fill-opacity', '0.5'); 
                j++;
              }
            } else {
              j = startCol;
              while (j <= endCol) {
                el = document.getElementById('block_' + j + '_' + startRow);
                el.setAttribute('fill', color);
                el.setAttribute('fill-opacity', '0.5'); 
                j++;
              }
            }

          }
    
          i++;
        }
        
        // show current user selection
        
        if (Object.prototype.hasOwnProperty.call(widget, 'state')) {
          
          startCol = widget.state.startcol;
          startRow = widget.state.startrow;
          endCol = widget.state.endcol;
          endRow = widget.state.endrow;

          if (startCol > endCol) {
            tmpInt = startCol;
            startCol = endCol;
            endCol = tmpInt;
          }

          if (startRow > endRow) {
            tmpInt = startRow;
            startRow = endRow;
            endRow = tmpInt;
          }
          
          if (startCol >= 0) {			

            if (startCol == endCol) {
              j = startRow;
              while (j <= endRow) {
                el = document.getElementById('block_' + startCol + '_' + j);
                el.setAttribute('fill', color);
                el.setAttribute('fill-opacity', '0.5'); 
                j++;
              }
            } else if (startRow == endRow) {
              j = startCol;
              while (j <= endCol) {
                el = document.getElementById('block_' + j + '_' + startRow);
                el.setAttribute('fill', '#444444');
                el.setAttribute('fill-opacity', '0.5'); 
                j++;
              }
            } else {
                el = document.getElementById('block_' + startCol + '_' + startRow);
                el.setAttribute('fill', '#444444');
                el.setAttribute('fill-opacity', '0.5');               
            }
            
          }
          
        }       

      },

      inputCorrect(input, answer) {
        var result;
        var i;
        var tmpArr;
        
        tmpArr = answer.split("|");
        result = false;
        i = 0;
        while ((i < tmpArr.length) && !result) {
          result = (input == tmpArr[i]);
          i = i + 1;
        }
        
        return result;
      },

      inputMatchLen(input, answer) {
        var result;
        var answerArr;
        var i;
        var maxLen;

        result = false

        if (answer.indexOf('|') < 0) {

          result = (input.length >= answer.length);
      
        } else {
          
          answerArr = answer.split('|');
          i = 0;
          while ((i < answerArr.length) && !result) {
            result = (input == answerArr[i]);
            i++;
          }
            
          if (!result) {
            
            maxLen = 0;
            i = 0;
            while (i < answerArr.length) {
              if (answerArr[i].length > maxLen) {
                maxLen = answerArr[i].length;
              }
              i++;
            }

            result = (input.length >= maxLen);    
        
          }
      
        }

        return result
      },

      isTouchScreenDevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints;
      },

      keyAt(x, y) {
        var result = '';

        switch (this.keyboard.type) {
          case 'alpha1':
            result = keyboardUtils.keyAt_alpha1(x - this.keyboard.x, y - this.keyboard.y);
            break;
          case 'alphanumeric1':
            result = keyboardUtils.keyAt_alphanumeric1(x - this.keyboard.x, y - this.keyboard.y);            
            break;            
          case 'numeric1':
            result = keyboardUtils.keyAt_numeric1(x - this.keyboard.x, y - this.keyboard.y);
            break;
        }

        result = keyboardUtils.setKeyCase(result, this.keyboard.uppercase);

        return result;
      },

      keyDown(e) {
        if (e.key == 'Backspace') {
          this.handleKey(8);
        }         
      },

      keyPress(e) {
        this.handleKey(e.which);
      },

      async loadExercise(lessonWidgetId, exerciseId) {                
  
        const { data } = await CourseRepository.getExercise(lessonWidgetId, exerciseId);
        this.loaded = true;
        if (data.success) {          
          var i;
          
          this.exercise = data.data.exercise;
          this.exerciseTitle = data.data.exercise.title;
          this.sequence = [];
          i = 0;
          while (i < this.exercise.script.questions.length) {
            this.sequence.push(i);
            i++;
          }

          // shuffle questions

          if (this.exercise.script.order == 'random') {
            this.sequence.sort(() => Math.random() - 0.5);		
          }

          this.total = 0;
          this.marks = 0;

          this.questionIndex = -1;
        } else {          
          alert(data.message);
        }

      },

      /*loadExercise(exercise) {                
        var i;

        this.exercise = exercise;
        this.exerciseTitle = exercise.title;
        this.sequence = [];
        i = 0;
        while (i < this.exercise.script.questions.length) {
          this.sequence.push(i);
          i++;
        }

        // shuffle questions

        if (this.exercise.script.order == 'random') {
          this.sequence.sort(() => Math.random() - 0.5);		
        }

        this.total = 0;
        this.correct = 0;

        this.questionIndex = -1;

      },*/

      markQuestion() {
        var i;		
        var j;
        var widget;
        var item;
        var isInput;
        var itemMarks;
        var marks = 0;
        var total = 0;
        var result;

        this.correct = true;

        i = 0;
        while (i < this.question.widgets.length) {
          widget = this.question.widgets[i];
          switch (widget.type) {
            case 'audio':
              //this widget does not have inputs
              break;	

            case 'backgroundcolor':
              //this widget does not have inputs
              break;	

            case 'dragtext':
              if (Object.prototype.hasOwnProperty.call(widget, 'marks')) {
                itemMarks = widget.marks;				
              } else {
                itemMarks = 1;
              }              
              total = total + itemMarks;
              if (widget.userinput == widget.answerindex) {
                if (!this.usedHelp) {
                  marks = marks + itemMarks;
                }
              } else {
                this.correct = false;
              }
              break;

            case 'ellipse':
              //this widget does not have inputs
              break;	

            case 'help':
              //this widget does not have inputs
              break;	
              
            case 'image':
              //this widget does not have inputs
              break;
              
            case 'line':
              //this widget does not have inputs
              break;					

            case 'lineinputs':
              if (Object.prototype.hasOwnProperty.call(widget, 'marks')) {
                itemMarks = widget.marks;				
              } else {
                itemMarks = 1;
              }              
              total = total + itemMarks;

              if (Object.prototype.hasOwnProperty.call(widget, 'userinput')) {
                if (widget.userinput.length == widget.items.length) {
                  j = 0;
                  while ((j < widget.userinput.length) && this.correct) {
                    this.correct = (widget.userinput[j].from == widget.userinput[j].to);
                    j++;
                  }
                } else {
                  this.correct = false;
                }
              } else {
                this.correct = false;
              }

              if (this.correct) {
                marks = marks + itemMarks;
              }

              break;

            case 'rectangle':
              //this widget does not have inputs
              break;			

            case 'textline':
              j = 0;
              while (j < widget.items.length) {
                item = widget.items[j];
                isInput = false;
                if (Object.prototype.hasOwnProperty.call(item, 'input')) {
                  isInput = item.input;
                }
                if (isInput) {
                  if (Object.prototype.hasOwnProperty.call(item, 'marks')) {
                    itemMarks = item.marks;
                  } else {
                    itemMarks = 1;
                  }
                  total = total + itemMarks;							
                  if (this.inputCorrect(item.userinput, item.text)) {
                    if (!this.usedHelp) {
                      marks = marks + itemMarks;
                    }
                  } else {
                    this.correct = false;
                  }
                }						
                j++;
              }
              break;	

            case 'textmc':
              if (Object.prototype.hasOwnProperty.call(widget, 'marks')) {
                itemMarks = widget.marks;				
              } else {
                itemMarks = 1;
              }
              total = total + itemMarks;
              result = true;
              j = 0;
              while ((j < widget.items.length) && result) {
                if (widget.items[j].correct != widget.userinput[j]) {
                  result = false;
                }
                j++;
              }
              if (result) {
                if (!this.usedHelp) {
                  marks = marks + itemMarks;
                }
              } else {
                this.correct = false;
              }					
              break;

            case 'wordblock':
              itemMarks = 1;
              total = total + itemMarks;
              result = true;
              if (!this.usedHelp) {
                marks = marks + itemMarks;
              }              
              break;
              
            default:
              alert('[markQuestion] Unknown widget: ' + widget.type);
          }
          i++;
        }	

        this.questionsCompleted++;
        this.marks = marks;
        this.total = total;

        if (!this.correct) {
          this.help.visible = true;
        }

        this.renderQuestion();        

        if (this.total > 0) {
          this.$emit('showTick', this.correct);
        }

      },

      nextQuestion() {
        var key;
        var n;
        var name;

        if ((this.totalQuestions > 0) && (this.questionsCompleted >= this.totalQuestions)) {

          this.$emit('allQuestionsCompleted');

        } else {

          this.clearAudio();
          this.usedHelp = false;

          this.help = {
            x: 0,
            y: 0,
            visible: false,
            minX: 0,
            minY: 0,
            maxX: 0,
            maxY: 0,
            hasItems: false
          },

          this.keyboard = {
            type: 'alphanumeric1',
            visible: false,        
            x: 160,
            y: 400,
            font: 'Arial'  
          }

          this.questionIndex++;
          if (this.questionIndex >= this.sequence.length) {
            this.questionIndex = 0;
          }
          this.question = JSON.parse(JSON.stringify(this.exercise.script.questions[this.sequence[this.questionIndex]]));

          // switch to portrait widgets if in portrait mode and portrait is available

          this.renderLandscape = true;
          if (!this.landscape) {
            if (Object.prototype.hasOwnProperty.call(this.question, 'pwidgets')) {
              this.question.widgets = this.question.pwidgets;
              this.renderLandscape = false;
            } 
          }

          // set locale if undefined
          
          if (!Object.prototype.hasOwnProperty.call(this.question.phrases, this.locale)) {
            if (Object.keys(this.question.phrases).length > 0) {
              this.locale = Object.keys(this.question.phrases)[0];
            }
          }
          this.phrases = this.question.phrases[this.locale];

          let useLogic = false;
          if (Object.prototype.hasOwnProperty.call(this.question, 'uselogic')) {
            useLogic = this.question.uselogic;
          }

          if (useLogic) {

            // execute logic

            this.symbols = this.runLogic(this.question.logic);

          } else {

            // execute script
            
            this.symbols = Synergy.run(this.question.script);
          
          }

          
          // format float symbols for display

          for (key in this.symbols) {
            n = this.symbols[key];
            if (Number(n) === n && n % 1 !== 0) {
              this.symbols[key] = Math.round(n * 100000000) / 100000000;
            }          
          }

          // substitute symbols

          this.substituteSymbols(this.question.widgets);

          // substitute phrases

          this.substitutePhrases(this.question.widgets);

          // substitute name
                  
          name = this.$store.state.user.firstname.trim();
          if (name == '') {
            name = 'Learner';
          }
          this.substituteName(this.question.widgets, name);

          // shuffle items 
          
          this.shuffleItems();	

          // initialize state

          this.widgetIndex = -1;
          this.completed = false;

          // go to next widget

          this.nextWidget();        

          // render Svg

          this.renderQuestion();  

          this.$emit('questionReady');

        }

      },

      nextWidget() {
        var foundInput;
        var widget;
        var i;
        var audioClip;

        this.keyboard.visible = false;

        if (!this.completed) {
                
          foundInput = false;
          do {
            
            this.widgetIndex++;
            if (this.widgetIndex >= this.question.widgets.length) {
              
              this.widgetIndex--;
              this.completed = true;
              this.markQuestion();

              //this.clearAudio();
              //this.$emit('questionCompleted', this.correct, this.marks, this.total);

            } else {
            
              widget = this.question.widgets[this.widgetIndex];
              switch (widget.type) {
                case 'audio':
                  audioClip = new Audio(this.question.audioclips[widget.audioclipid]);
                  //audioClip.addEventListener('canplaythrough', function() {  
                  //  audioClip.play();
                  //  alert('can play through');
                  //}, false);
                  //audioClip.load();                  
                  this.audioQueue.push(audioClip);
                  this.audioQueueSaved.push(audioClip);
                  this.playAudioQueue();
                  this.$emit('playedAudio');                  
                  break;
                case 'dragtext':	
                  foundInput = true;

                  break;	
                case 'help':
                  this.help.x = parseInt(widget.x);
                  this.help.y = parseInt(widget.y);
                  this.help.align = 'left';
                  if (Object.prototype.hasOwnProperty.call(widget, 'align')) {
                    this.help.align = widget.align;
                  }                  
                  break;    
                case 'lineinputs':	
                  foundInput = true;
                  break;                              
                case 'textline':
                  i = 0;
                  while ((i < widget.items.length) && !foundInput) {
                    if (Object.prototype.hasOwnProperty.call(widget.items[i], 'input')) {
                      foundInput = widget.items[i].input;
                    }
                    if (foundInput) {                 
                      this.keyboard.type = 'alphanumeric1';
                      if (Object.prototype.hasOwnProperty.call(widget, 'keyboard')) {
                        this.keyboard.type = widget.keyboard.type;
                        this.keyboard.x = widget.keyboard.x;
                        this.keyboard.y = widget.keyboard.y;
                      } 
                      this.keyboard.font = widget.font; 
                      this.keyboard.visible = true;
                    }                    
                    i++;
                  }          
                  this.cursorVisible = true;
                  this.cursorTimeout = setTimeout(this.flashCursor, 500);
                  break;
                case 'textmc':	
                  foundInput = true;
                  break;		
                case 'wordblock':	
                  foundInput = true;
                  break;								
                default:
                  // skip widget
              }
            
            }

          } while (!this.completed && !foundInput);

          if (!foundInput) {
            this.completed = true;                
            this.$emit('questionCompleted', this.correct, this.marks, this.total, this.usedHelp);
          }

        }		

      },

      playAudioQueue() {
        var t = this;	
        if (this.currentAudioClip != null) {
          if (this.currentAudioClip.ended) {
            this.currentAudioClip = null;
          } else {
            setTimeout(function(){ t.playAudioQueue(); }, 10);
          }
        }
      
        if (this.currentAudioClip == null) {
          if (this.audioQueue.length > 0) {
            this.currentAudioClip = this.audioQueue[0];
            this.audioQueue.shift();		
            try {              
              //alert('55');
              this.currentAudioClip.play();	  
              //this.currentAudioClip.pause();   
              //this.currentAudioClip.play();	       
            }
            catch (err) {
              alert(err.message);
            }
            setTimeout(function(){ t.playAudioQueue(); }, 50);
          }
        } 
      },      

      prepTextLine(widget, help) {
        var result = {};
        var wrapWidth = 1216;
        var textItems = {};
        var lineWidth;
        var controlWidth;
        var i, j;
        var item;
        var correct;
        var completed;
        var userInput;
        var tmpStr;
        var input;
        var tmpArr;
        var wordWidth;
        var nextItem;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");  
        var word;
        var currentLine;
        var currentIndex;
        var prevWord;
        var superscript;
        var emptyInput;
        var firstInput;        
        var cursorWidth;
        var showCursor;

        ctx.font = parseInt(widget.fontsize) + 'px ' + widget.font;		

        if (Object.prototype.hasOwnProperty.call(widget, 'wrapwidth')) {
          wrapWidth = parseInt(widget.wrapwidth);
        }				
        
        result["words"] = [];
        result["lines"] = [];
        result["lines"].push({"width": 0});

        textItems = widget.items;
        lineWidth = 0;
        controlWidth = 0;
        firstInput = true;
        emptyInput = false;
        i = 0;
        while (i < textItems.length) {

          item = textItems[i];
          input = false;
          if (!help) {
            if (Object.prototype.hasOwnProperty.call(item, 'input')) {
              input = item.input;
            }		            
          }
          if (input) {
            userInput = '';
            if (Object.prototype.hasOwnProperty.call(item, 'userinput')) {
              userInput = item.userinput;
            }	            
            correct = this.inputCorrect(userInput, item.text);
            completed = this.inputMatchLen(userInput, item.text);
            emptyInput = false;
            if (userInput == '') {
              emptyInput = true;
            }
          }
          if (!input) {
            tmpStr = item.text;
            tmpStr = tmpStr.split("|")[0];
          } else {
            tmpStr = userInput;
            if (tmpStr == '') {
              tmpStr = ' ';
              emptyInput = true;
            }
          }
          superscript = false;
          if (Object.prototype.hasOwnProperty.call(item, 'superscript')) {
            superscript = item.superscript;
          }	  

          if (tmpStr != '') {
            tmpArr = tmpStr.split(' ');

            if ((tmpArr[tmpArr.length - 1] == '') && !input) {
              tmpArr[tmpArr.length - 2] = tmpArr[tmpArr.length - 2] + ' ';
              tmpArr.pop();
            }

            // add spaces
            if (tmpArr.length >= 1) {
              j = 0;
              while (j < (tmpArr.length - 1)) {
                tmpArr[j] = tmpArr[j] + ' ';
                j++;
              }
            }

            j = 0;
            while (j < tmpArr.length) {

              cursorWidth = 0;
              showCursor = false;

              if (input && !completed) {
                if (firstInput) {
                  cursorWidth = widget.fontsize * 0.4;
                  showCursor = true;
                  firstInput = false;
                }
              }

              if ((tmpArr[j].trim() == '') && (cursorWidth > 0)) {
                wordWidth = cursorWidth;
              } else {
                if (tmpArr[j].trim() == '') {
                  tmpArr[j] = ' ';
                }
                if (superscript) {
                  wordWidth = parseInt(ctx.measureText(tmpArr[j]).width) * 0.7 + cursorWidth; 
                } else {
                  wordWidth = parseInt(ctx.measureText(tmpArr[j]).width) + cursorWidth; 
                }       
              }       

              if (lineWidth <= 0) {
                lineWidth = wordWidth;
                result.lines[result.lines.length - 1].width = lineWidth;
              } else {
                if (((lineWidth + wordWidth) <= wrapWidth) || (tmpArr[j] == '.')) {
                  lineWidth = parseInt(lineWidth) + parseInt(wordWidth);
                  result.lines[result.lines.length - 1].width = lineWidth;
                } else {
                  lineWidth = wordWidth;
                  result.lines.push({"width": lineWidth});
                }
              }

              if (lineWidth > controlWidth) {
                controlWidth = lineWidth;
              }

              nextItem = {};
              if (input && emptyInput) {
                nextItem['text'] = '';
              } else {
                nextItem['text'] = tmpArr[j];
              }              
              nextItem['itemindex'] = i;
              nextItem['input'] = input;
              nextItem['superscript'] = superscript;
              if (input) {
                nextItem['correct'] = correct;
                nextItem['completed'] = completed;
              }
              nextItem["showcursor"] = showCursor;
              nextItem["width"] = wordWidth;
              nextItem["line"] = result.lines.length - 1;
              result.words.push(nextItem);
              j++;
            }

          }

          i++;
        }

        result["controlwidth"] = controlWidth;
        result["controlheight"] = result.lines.length * parseInt(widget.fontsize) * 1.5;

        // merge sequential words on same line

        if (result.words.length > 1) {
          word = result.words[0];
          currentLine = word.line;
          currentIndex = word.itemindex;
          i = 1;
          while (i < result.words.length) {
            word = result.words[i];
            if ((word.line == currentLine) && (word.itemindex == currentIndex)) {
              prevWord = result.words[i - 1];
              prevWord.text = prevWord.text + word.text;
              prevWord.width = parseInt(prevWord.width) + parseInt(word.width);
              result.words.splice(i, 1);
            } else {
              currentLine = word.line;
              currentIndex = word.itemindex;
              i++;
            }
            
          }
        }

        result['xanchoroffset'] = 0;
        if (Object.prototype.hasOwnProperty.call(widget, 'anchorindex')) {                  
          i = 0;
          while (i < widget.anchorindex) {
            result['xanchoroffset'] = result['xanchoroffset'] - result.words[i].width;
            i++;
          }
        }
        return result;
      },

      prevWidget() {
        var foundInput;
        var widget;
        var i;
        var currentIndex;
        var item;
        
        if (!this.completed) {
            
          currentIndex = this.widgetIndex;
            
          foundInput = false;
          while ((this.widgetIndex > 0) && !foundInput) {
            this.widgetIndex--;

            widget = this.question.widgets[this.widgetIndex];
            switch (widget.type) {
              case 'dragtext':	
                foundInput = true;
                break;								
              case 'textline':
                i = widget.items.length - 1;
                while ((i >= 0) && !foundInput) {
                  item = widget.items[i];
                  if (Object.prototype.hasOwnProperty.call(item, 'input')) {								
                    foundInput = item.input;
                    if (foundInput) {
                      item.userinput = item.userinput.substring(0, item.userinput.length - 1);
                    }								
                  }
                  i--;
                }
                break;
              case 'textmc':
                if (Object.prototype.hasOwnProperty.call(widget, 'userinput')) {
                  i = 0;
                  while (i < widget.userinput.length) {
                    widget.userinput[i] = false;
                    i++;
                  }
                }
                foundInput = true;
                break;
              default:
                // skip widget
            }
            
          } 
          
          if (!foundInput) {
            this.widgetIndex = currentIndex;
          } 

        }		
      },

      renderKeyboard() {
        var svg = '';

        if (!Object.prototype.hasOwnProperty.call(this.keyboard, 'uppercase')) {	
          this.keyboard['uppercase'] = false;
        }

        if (this.keyboard.visible) {
          switch (this.keyboard.type) {
            case 'alpha1':
              svg = keyboardUtils.createKeyboard_alpha1(this.keyboard.x, this.keyboard.y, this.keyboard.font, this.keyboard.uppercase);
              break;
            case 'alphanumeric1':
              svg = keyboardUtils.createKeyboard_alphanumeric1(this.keyboard.x, this.keyboard.y, this.keyboard.font, this.keyboard.uppercase);
              break;              
            case 'numeric1':
              svg = keyboardUtils.createKeyboard_numeric1(this.keyboard.x, this.keyboard.y, this.keyboard.font);
              break;            
          }
        }

        return svg;

      },

      async renderQuestion() {
        var svg = '';
        var i;
        var widget;
        let helpItem = {};
        var helpX;
        var backgroundColor = '#ffffff';

        //this.help.hasItems = false;
        //this.help.minX = 0;
        //this.help.minY = 0;
        //this.help.maxX = 0;
        //this.help.maxY = 0;        

        // render background color

        i = 0;
        while (i <= this.widgetIndex) {
          widget = this.question.widgets[i];
          switch (widget.type) {
            case 'backgroundcolor':   
              backgroundColor = '#000000';
              if (Object.prototype.hasOwnProperty.call(widget, 'color')) {	
                if (widget.color.substring(0, 1) == '#') {
                  backgroundColor = widget.color;
                } else {
                  backgroundColor = '#' + widget.color;
                }              
              }              
              break;                           
          }
          i++;
        }	             

        svg = svg + '<rect x="0"' +
          ' y="0"' +
          ' width="100%"' +
          ' height="100%"' +
          ' fill="' + backgroundColor + '"' +
          '></rect>';

        // render widgets

        if (!this.help.visible) {

          i = 0;
          while (i <= this.widgetIndex) {
            widget = this.question.widgets[i];
            switch (widget.type) {
              case 'audio':
                // nothing to render
                break;
              case 'backgroundcolor':   
                backgroundColor = '#000000';
                if (Object.prototype.hasOwnProperty.call(widget, 'color')) {	
                  if (widget.color.substring(0, 1) == '#') {
                    backgroundColor = widget.color;
                  } else {
                    backgroundColor = '#' + widget.color;
                  }              
                }              
                break;                           
              case 'dragtext':
                svg = svg + this.render_dragText(widget, i, false, false);
                break;		
              case 'ellipse':
                svg = svg + this.render_ellipse(widget, i, false, false);
                break;                  
              case 'help':
                // nothing to render
                break;
              case 'image':
                svg = svg + await this.render_image(widget, i, false, false);
                break;	
              case 'line':
                svg = svg + this.render_line(widget, i, false, false);
                break;  
              case 'lineinputs':
                svg = svg + this.render_lineinputs(widget, i, false, false);
                break;                 
              case 'rectangle':
                svg = svg + this.render_rectangle(widget, i, false, false);
                break;                               
              case 'textline':
                svg = svg + this.render_textLine(widget, i, false, false);
                break;
              case 'textmc':
                svg = svg + this.render_textMC(widget, i, false, false);
                break;		
              case 'wordblock':
                svg = svg + this.render_wordblock(widget, i, false, false);
                break;						
              default:
                alert('[renderQuestion] Unknown widget: ' + widget.type);
            }
            i++;
          }	        
        
        }

        // render keyboard

        if (!this.help.visible) {
          svg = svg + this.renderKeyboard();
        }

        // render help

        if (this.help.visible) {

          // determine helpbox dimensions

          this.helpBox = [];

          i = 0;
          while (i < this.question.widgets.length) {
            widget = this.question.widgets[i];
            switch (widget.type) {
              case 'audio':
                // nothing to render
                break;
              case 'backgroundcolor':
                // nothing to render
                break;                
              case 'dragtext':
                svg = svg + this.render_dragText(widget, i, false, true);
                break;		
              case 'ellipse':
                svg = svg + this.render_ellipse(widget, i, false, true);
                break;                 
              case 'help':
                helpItem = {
                  x: widget.x,
                  y: widget.y,
                  minX: 0,
                  maxX: 0,
                  minY: 0,
                  maxY: 0,
                  foundHelp: false
                };
                this.helpBox.push(helpItem);
                break;
              case 'image':
                svg = svg + await this.render_image(widget, i, false, true);
                break;	
              case 'line':
                svg = svg + this.render_line(widget, i, false, true);
                break;
              case 'lineinputs':
                svg = svg + this.render_lineinputs(widget, i, false, true);
                break;                
              case 'rectangle':
                svg = svg + this.render_rectangle(widget, i, false, true);
                break;                
              case 'textline':
                svg = svg + this.render_textLine(widget, i, false, true);
                break;
              case 'textmc':
                svg = svg + this.render_textMC(widget, i, false, true);
                break;		
              case 'wordblock':
                svg = svg + this.render_wordblock(widget, i, false, true);
                break;						
              default:
                alert('[helpBoxDimensions] Unknown widget: ' + widget.type);
            }
            i++;
          }

          // draw help boxes

          i = 0;
          while (i < this.helpBox.length) {

            helpItem = this.helpBox[i];

            helpX = helpItem.x;

            switch (this.help.align) {
              case 'center':
                helpX = helpX - (helpItem.maxX - helpItem.minX + 20) / 2;
                break;
              case 'right':
                helpX = helpX - (helpItem.maxX - helpItem.minX + 20);
                break;
            }
            svg = svg + '<rect x="' + (helpX - 10) + '"' +
              ' y="' + (helpItem.y - 10) + '"' +
              ' width="' + (helpItem.maxX - helpItem.minX + 20) + '"' +
              ' height="' + (helpItem.maxY - helpItem.minY + 20) + '"' +
              ' fill="#ffffff"' +
              ' stroke="#376dc7"' +
              ' stroke-width="2"' +
              ' rx="8"' +
              ' ry="8"' +
              '></rect>';            

            i++;
          }

          // render help box content

          this.helpBoxIndex = -1;
          i = 0;          
          while (i < this.question.widgets.length) {
            widget = this.question.widgets[i];
            if (widget.type == 'help') {
              this.helpBoxIndex++;
            } else if ((this.helpBoxIndex >= 0) && (this.helpBoxIndex < this.helpBox.length)) {
              helpItem = this.helpBox[this.helpBoxIndex];

              helpX = helpItem.x;

              switch (this.help.align) {
                case 'center':
                  helpX = helpX - (helpItem.maxX - helpItem.minX + 20) / 2;
                  break;
                case 'right':
                  helpX = helpX - (helpItem.maxX - helpItem.minX + 20);
                  break;
              }

              if (widget.help) {
                switch (widget.type) {               
                  case 'textline':
                    svg = svg + this.render_textLine(widget, i, true, false, helpX - helpItem.minX, helpItem.y - helpItem.minY);
                    break;
                }
              }
            }

            i++;
          }          

        }

        /*if (this.help.visible && this.help.hasItems) {

          helpX = this.help.x;

          switch (this.help.align) {
            case 'center':
              helpX = helpX - (this.help.maxX - this.help.minX + 20) / 2;
              break;
            case 'right':
              helpX = helpX - (this.help.maxX - this.help.minX + 20);
              break;
          }

          svg = svg + '<rect x="' + (helpX - 10) + '"' +
            ' y="' + (this.help.y - 10) + '"' +
            ' width="' + (this.help.maxX - this.help.minX + 20) + '"' +
            ' height="' + (this.help.maxY - this.help.minY + 20) + '"' +
            ' fill="#ffffff"' +
            ' stroke="#376dc7"' +
            ' stroke-width="2"' +
            ' rx="8"' +
            ' ry="8"' +
            '></rect>';

          i = 0;          
          while (i < this.question.widgets.length) {
            widget = this.question.widgets[i];
            if (widget.help) {
              switch (widget.type) {
                case 'image':
                  //Render_image(ctx, question, widget, i, question.state.completed, true, helpX - helpMinX, helpY - helpMinY);
                  alert('todo: image help')
                  break;								
                case 'textline':
                  svg = svg + this.render_textLine(widget, i, true, helpX - this.help.minX, this.help.y - this.help.minY);
                  break;
              }
            }
            i++;
          }

        }    */    

        this.exerciseSvg = svg;
      },


      render_dragText(widget, index, drawHelp, helpDimensions, xOffset=0, yOffset=0) { // eslint-disable-line
        var svg = '';
        var i;
        var item;
        var font = 'Arial';
        var fontSize;
        var showText = true;
        var textColor;
        var x, y;
        var align;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        if (Object.prototype.hasOwnProperty.call(widget, 'font')) {			
          font = widget.font;
        }

        fontSize = parseInt(widget.fontsize);   
        
        ctx.font = widget.fontsize + 'px ' + font;		

        if (!Object.prototype.hasOwnProperty.call(widget, 'dropindex')) {
          widget['dropindex'] = -1;
        }
        
        i = 0;
        while (i < widget.items.length) {
          
          item = widget.items[i];
          showText = true;

          align = 'left';
          if (Object.prototype.hasOwnProperty.call(item, 'align')) {
            align = item.align;
          }

          if (!Object.prototype.hasOwnProperty.call(item, 'dragx')) {
            item['dragx'] = item.x;
          }
          if (!Object.prototype.hasOwnProperty.call(item, 'dragy')) {
            item['dragy'] = item.y;
          }

          item['id'] = 'dragtext_item_' + index + '_' + i;          

          if ((this.completed) || (this.help.visible)) {
            
            if (Object.prototype.hasOwnProperty.call(widget, 'hideitemswhendone')) {				
              if (widget.hideitemswhendone && (i != widget.userinput)) {
                showText = false;
              }
            }

            if (showText) {				
              if (i == widget.answerindex) {
                textColor = '#77bd02';
              } else if (i == widget.userinput) {
                textColor = '#da2b02';
              } else {
                textColor = '#000000';
              }				
            }
            
          } else {
            textColor = '#000000';
          }

          if (this.widgetIndex != index) {
            if (i != widget.userinput) {
              showText = false;
            }
          }				
            
          if (showText) {

            x = item.dragx;
            y = item.dragy;

            switch (align) {
              case 'center':
                x = x - parseInt(ctx.measureText(item.text).width) / 2;
                break;            
              case 'right':
                x = x - parseInt(ctx.measureText(item.text).width);
                break;          
            }

            svg = svg + '<text' + 
              ' id="' + item.id + '"' +
              ' x="' + x + '"' +
              ' y="' + y + '"' +
              ' font-family="' + font + '"' +
              ' font-size="' + fontSize + '"' +
              ' fill="' + textColor + '"' +
              ' style="cursor: pointer; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;"' + 
              '>' + item.text + '</text>';
          }
          
          i++;
        }

        return svg; 
      },

      render_ellipse(widget, index, drawHelp, helpDimensions, xOffset=0, yOffset=0) { // eslint-disable-line
        var svg = '';
        var x;
        var y;
        var width;
        var height;      
        var stroke = widget.bordercolor;
        var fill = widget.fillcolor;
        var strokeWidth = widget.borderwidth;
        var shadow = widget.shadow;  

        var visible = 'always';
        var isVisible = true;
        if (Object.prototype.hasOwnProperty.call(widget, 'visible')) {
          visible = widget.visible;
          switch (visible) {
            case "oncomplete":
              isVisible = this.completed;
              break;
            case "oncorrect":
              if (this.completed) {
                isVisible = this.correct;
              } else {
                isVisible = false;
              }
              break;
            case "onincorrect":
              if (this.completed) {
                isVisible = !this.correct;
              } else {
                isVisible = false;
              }              
              break;
          }
        } 

        if (isVisible) {

          x = parseInt(this.getProperty(widget, 'x', 0));
          y = parseInt(this.getProperty(widget, 'y', 0));
          width = parseInt(this.getProperty(widget, 'width', 0));
          height = parseInt(this.getProperty(widget, 'height', 0));

          if (fill.substring(0, 1) != '#') {
            fill = '#' + fill;
          }
          if (stroke.substring(0, 1) != '#') {
            stroke = '#' + stroke;
          }

          if (shadow) {
            svg += '<ellipse ' +
              ' cx="' + (x + width / 2 + 4) + '"' +
              ' cy="' + (y + height / 2 + 4) + '"' +
              ' rx="' + (width / 2) + '"' +
              ' ry="' + (height / 2) + '"' +
              ' fill="#000000"' +     
              ' fill-opacity="0.2"';            
            svg += '></ellipse>';           
          }

          svg += '<ellipse' +
            ' cx="' + (x + width / 2) + '"' +
            ' cy="' + (y + height / 2) + '"' +
            ' rx="' + (width / 2) + '"' +
            ' ry="' + (height / 2) + '"' +
            ' fill="' + fill + '"' +     
            ' stroke="' + stroke + '"' +     
            ' stroke-width="' + strokeWidth + '"';            
          svg += '></ellipse>'; 

        }

        return svg; 
      },

      async render_image(widget, index, drawHelp, helpDimensions, xOffset=0, yOffset=0) { // eslint-disable-line
        var svg = '';
        var align = 'left';
        var x = widget.x;
        var dim;
        var width = 0;
        var height = 0;

        var visible = 'always';
        var isVisible = true;
        if (Object.prototype.hasOwnProperty.call(widget, 'visible')) {
          visible = widget.visible;
          switch (visible) {
            case "oncomplete":
              isVisible = this.completed;
              break;
            case "oncorrect":
              if (this.completed) {
                isVisible = this.correct;
              } else {
                isVisible = false;
              }
              break;
            case "onincorrect":
              if (this.completed) {
                isVisible = !this.correct;
              } else {
                isVisible = false;
              }              
              break;
          }
        } 

        if (isVisible) {

          if (Object.prototype.hasOwnProperty.call(widget, 'align')) {
            align = widget.align;
          }

          if (Object.prototype.hasOwnProperty.call(widget, 'width')) {
            width = widget.width;
          }			
          if (Object.prototype.hasOwnProperty.call(widget, 'height')) {
            height = widget.height;
          }	

          if ((width <= 0) || (height <= 0)) {
            dim = await this.getImageDimensions(this.question.images[widget.imageid]);      
            width = dim.width;
            height = dim.height;
          }

          switch (align) {
            case 'center':     
              x = x - (width / 2);
              break;
            case 'right': 
              x = x - width;
              break;
          }

          if ((width > 0) && (height > 0)) {
            svg = '<image' +
              ' x="' + x + '"' +
              ' y="' + widget.y + '"' +
              ' preserveAspectRatio="none"' +
              ' width="' + width + 'px"' +
              ' height="' + height + 'px"' +            
              ' href="' + this.question.images[widget.imageid] + '"' +
              '></image>'; 
          }

        }

        return svg; 
      },

      render_line(widget, index, drawHelp, helpDimensions, xOffset=0, yOffset=0) { // eslint-disable-line
        var svg = '';
        var x1 = widget.x1;
        var y1 = widget.y1;
        var x2 = widget.x2;
        var y2 = widget.y2;      
        var color;
        var start = 'none';
        var end = 'none';
        var width = 2;

        var visible = 'always';
        var isVisible = true;
        if (Object.prototype.hasOwnProperty.call(widget, 'visible')) {
          visible = widget.visible;
          switch (visible) {
            case "oncomplete":
              isVisible = this.completed;
              break;
            case "oncorrect":
              if (this.completed) {
                isVisible = this.correct;
              } else {
                isVisible = false;
              }
              break;
            case "onincorrect":
              if (this.completed) {
                isVisible = !this.correct;
              } else {
                isVisible = false;
              }              
              break;
          }
        } 

        if (isVisible) {

          if (Object.prototype.hasOwnProperty.call(widget, 'width')) {
            width = widget.width;
          }      
          if (Object.prototype.hasOwnProperty.call(widget, 'start')) {
            start = widget.start;
          }			
          if (Object.prototype.hasOwnProperty.call(widget, 'end')) {
            end = widget.end;
          }        
          
          color = '#000000';
          if (Object.prototype.hasOwnProperty.call(widget, 'color')) {	
            if (widget.color.substring(0, 1) == '#') {
              color = widget.color;
            } else {
              color = '#' + widget.color;
            }              
          }

          if (end == 'arrow') {
            svg += '<defs>' +
              '<marker id="endmarker_' + index + '" markerWidth="10" markerHeight="7"' +
              ' refX="5" refY="3.5" orient="auto">' +
              '   <polygon fill="' + color + '" points="0 0, 10 3.5, 0 7" />' +
              ' </marker>' +
              '</defs>';
          }
          
          if (start == 'arrow') {
            svg += '<defs>' +
              '<marker id="startmarker_' + index + '" markerWidth="10" markerHeight="7"' +
              ' refX="5" refY="3.5" orient="auto">' +
              '   <polygon fill="' + color + '" points="0 3.5, 10 0, 10 7" />' +
              ' </marker>' +
              '</defs>';
          }

          svg += '<line' +
            ' x1="' + x1 + '"' +
            ' y1="' + y1 + '"' +
            ' x2="' + x2 + '"' +
            ' y2="' + y2 + '"' +
            ' stroke="' + color + '"' +     
            ' stroke-width="' + width + '"';
          if (start != 'none') {
            svg += ' marker-start="url(#startmarker_' + index + ')"';
          }
          if (end != 'none') {
            svg += ' marker-end="url(#endmarker_' + index + ')"';
          }             
          svg += '></line>'; 

        }

        return svg; 
      },

      render_lineinputs(widget, index, drawHelp, helpDimensions, xOffset=0, yOffset=0) { // eslint-disable-line
        var svg = '';
        var i;
        var x1; //= widget.x1;
        var y1; //= widget.y1;
        var x2; //= widget.x2;
        var y2; //= widget.y2;      
        var color;
        //var start = 'none';
        //var end = 'none';
        var width = 2;

        widget['id'] = 'lineinputs_' + index;          

        if (Object.prototype.hasOwnProperty.call(widget, 'linewidth')) {
          width = widget.linewidth;
        }    

        color = '#000000';
        if (Object.prototype.hasOwnProperty.call(widget, 'color')) {	
          if (widget.color.substring(0, 1) == '#') {
            color = widget.color;
          } else {
            color = '#' + widget.color;
          }              
        }

        if (Object.prototype.hasOwnProperty.call(widget, 'userinput')) {
          
          i = 0;
          while (i < widget.userinput.length) {

              x1 = widget.items[widget.userinput[i].from].x1;
              y1 = widget.items[widget.userinput[i].from].y1;
              x2 = widget.items[widget.userinput[i].to].x2;
              y2 = widget.items[widget.userinput[i].to].y2;

              if (this.completed) {
                if (widget.items[widget.userinput[i].from] == widget.items[widget.userinput[i].to]) {
                  color = '#77bd02';
                } else {
                  color = '#da2b02';
                }
              } else {
                color = '#000000';
                if (Object.prototype.hasOwnProperty.call(widget, 'color')) {	
                  if (widget.color.substring(0, 1) == '#') {
                    color = widget.color;
                  } else {
                    color = '#' + widget.color;
                  }              
                }
              }

              svg += '<line' +                
                ' x1="' + x1 + '"' +
                ' y1="' + y1 + '"' +
                ' x2="' + x2 + '"' +
                ' y2="' + y2 + '"' +
                ' stroke="' + color + '"' +     
                ' stroke-width="' + width + '"' + 
                '></line>';

            i++;
          }          
          
        }

        // drag line

        svg += '<line' +
          ' id="' + widget.id + '"' +          
          ' x1="-100"' +
          ' y1="-100"' +
          ' x2="-50"' +
          ' y2="-50"' +
          ' stroke="' + color + '"' +     
          ' stroke-width="' + width + '"';
        svg += '></line>';

        if (Object.prototype.hasOwnProperty.call(widget, 'fromindex')) {

          if (widget.fromindex >= 0) {

            if (widget.fromend) {
              x1 = widget.items[widget.fromindex].x2;
              y1 = widget.items[widget.fromindex].y2;
            } else {
              x1 = widget.items[widget.fromindex].x1;
              y1 = widget.items[widget.fromindex].y1;
            }
            x2 = widget.tox;
            y2 = widget.toy;

            svg += '<line' +
              ' x1="' + x1 + '"' +
              ' y1="' + y1 + '"' +
              ' x2="' + x2 + '"' +
              ' y2="' + y2 + '"' +
              ' stroke="' + color + '"' +     
              ' stroke-width="' + width + '"' + 
              '></line>';
            
          }

        }

        return svg; 
      },

      render_rectangle(widget, index, drawHelp, helpDimensions, xOffset=0, yOffset=0) { // eslint-disable-line
        var svg = '';
        var x = widget.x;
        var y = widget.y;
        var width = widget.width;
        var height = widget.height;      
        var stroke = widget.bordercolor;
        var fill = widget.fillcolor;
        var strokeWidth = widget.borderwidth;
        var cornerArc = widget.cornerarc / 2;
        var shadow = widget.shadow;  

        var visible = 'always';
        var isVisible = true;
        if (Object.prototype.hasOwnProperty.call(widget, 'visible')) {
          visible = widget.visible;
          switch (visible) {
            case "oncomplete":
              isVisible = this.completed;
              break;
            case "oncorrect":
              if (this.completed) {
                isVisible = this.correct;
              } else {
                isVisible = false;
              }
              break;
            case "onincorrect":
              if (this.completed) {
                isVisible = !this.correct;
              } else {
                isVisible = false;
              }              
              break;
          }
        } 

        if (isVisible) {

          if (fill.substring(0, 1) != '#') {
            fill = '#' + fill;
          }
          if (stroke.substring(0, 1) != '#') {
            stroke = '#' + stroke;
          }

          if (shadow) {
            svg += '<rect' +
              ' x="' + (x + 4) + '"' +
              ' y="' + (y + 4) + '"' +
              ' width="' + width + '"' +
              ' height="' + height + '"' +
              ' fill="#000000"' +     
              ' fill-opacity="0.2"' +     
              ' rx="' + cornerArc + '"' +
              ' ry="' + cornerArc + '"';            
            svg += '></rect>';           
          }

          svg += '<rect' +
            ' x="' + x + '"' +
            ' y="' + y + '"' +
            ' width="' + width + '"' +
            ' height="' + height + '"' +
            ' fill="' + fill + '"' +     
            ' stroke="' + stroke + '"' +     
            ' stroke-width="' + strokeWidth + '"' +
            ' rx="' + cornerArc + '"' +
            ' ry="' + cornerArc + '"';            
          svg += '></rect>'; 

        }

        return svg; 
      },

      render_textLine(widget, index, drawHelp, helpDimensions, xOffset=0, yOffset=0) { // eslint-disable-line
        var svg = '';
        var textline;
        var align;
        var lineIndex;
        var x, y;
        var words;
        var word;
        var i;
        var fontSize;
        var c1;
        var textColor;
        var font = 'Arial';    
        var help = false;
        var cursorX;

        var visible = 'always';
        var isVisible = true;
        if (Object.prototype.hasOwnProperty.call(widget, 'visible')) {
          visible = widget.visible;
          switch (visible) {
            case "oncomplete":
              isVisible = this.completed;
              break;
            case "oncorrect":
              if (this.completed) {
                isVisible = this.correct;
              } else {
                isVisible = false;
              }
              break;
            case "onincorrect":
              if (this.completed) {
                isVisible = !this.correct;
              } else {
                isVisible = false;
              }              
              break;
          }
        } 

        if (isVisible) {

          if (Object.prototype.hasOwnProperty.call(widget, 'font')) {			
            font = widget.font;
          }
          fontSize = parseInt(widget.fontsize);    

          if (Object.prototype.hasOwnProperty.call(widget, 'help')) {
            help = widget.help;
          }

          textline = this.prepTextLine(widget, drawHelp); 

          align = 'left';
          if (Object.prototype.hasOwnProperty.call(widget, 'align')) {			
            align = widget.align;
          }        
          lineIndex = 0;

          x = parseInt(widget.x) + textline.xanchoroffset;
          y = parseInt(widget.y);
          switch (align) {
            case 'center':
              x = x - textline.lines[lineIndex].width / 2;
              break;            
            case 'right':
              x = x - textline.lines[lineIndex].width;
              break;          
          }

          if (drawHelp) {
            x = x + parseInt(xOffset); 
            y = y + parseInt(yOffset);         
          }

          if ((drawHelp && widget.help) || (!drawHelp && !widget.helponly)) {

            words = textline.words;
            i = 0;
            while (i < words.length) {

              word = words[i];

              if (word.line != lineIndex) {
                lineIndex++;
                x = widget.x;
                y = widget.y + word.line * fontSize * 1.1; 
                switch (align) {
                  case 'center':
                    x = x - textline.lines[lineIndex].width / 2;
                    break;            
                  case 'right':
                    x = x - textline.lines[lineIndex].width;
                    break;          
                }

                if (drawHelp) {
                  x = x + xOffset; 
                  y = y + yOffset;         
                }

              }

              // draw input box

              if (word.input && !this.completed) {

                if (word.completed) {
                  c1 = '#eeeeee';
                  //c2 = '#444444';              
                } else {
                  c1 = '#bbbbbb';
                  //c1 = '#9bc6ff';
                  //c2 = '#566e8f';
                }

                if (!word.showcursor) {

                  if (!word.completed) {

                    if (word.superscript) {
                      svg = svg + '<rect x="' + (x + 10) + '"' +
                        ' y="' + (y + 10 - fontSize) + '"' +
                        ' width="' + (word.width - 20) + '"' +
                        ' height="' + (fontSize * 0.8 - 20) + '"' +
                        ' rx="8"' +
                        ' ry="8"' +
                        ' fill="' + c1 + '"' + 
                        ' stroke="none"' +                  
                        '></rect>';
                    } else {
                      svg = svg + '<rect x="' + (x + 10) + '"' +
                        ' y="' + (y + 10 - fontSize) + '"' +
                        ' width="' + (word.width - 20) + '"' +
                        ' height="' + (fontSize * 1.2 - 20) + '"' +
                        ' rx="8"' +
                        ' ry="8"' +
                        ' fill="' + c1 + '"' + 
                        ' stroke="none"' +                  
                        '></rect>';
                    }

                  }

                }

              }

              // print word

              let shadow = false;
              let shadowColor = '#dddddd';
              if (Object.prototype.hasOwnProperty.call(widget, 'shadow')) {			
                shadow = widget.shadow;
              }
              if (Object.prototype.hasOwnProperty.call(widget, 'shadowcolor')) {	
                if (widget.shadowcolor.substring(0, 1) == '#') {
                  shadowColor = widget.shadowcolor;
                } else {
                  shadowColor = '#' + widget.shadowcolor;
                }              
              }

              textColor = '#000000';
              if (Object.prototype.hasOwnProperty.call(widget, 'color')) {	
                if (widget.color.substring(0, 1) == '#') {
                  textColor = widget.color;
                } else {
                  textColor = '#' + widget.color;
                }              
              }

              if (drawHelp) {
                textColor = '#376dc7';
              } else {
                if (word.input) {
                  if (this.completed) {
                    if (word.correct) {
                      textColor = '#77bd02';
                    } else {
                      textColor = '#da2b02';
                    }
                  }
                }
              }

              let boldStr = '';
              if (Object.prototype.hasOwnProperty.call(widget, 'bold')) {	
                if (widget.bold) {
                  boldStr = ' font-weight="bold"';
                }
              }

              let italicStr = '';
              if (Object.prototype.hasOwnProperty.call(widget, 'italic')) {	
                if (widget.italic) {
                  italicStr = ' font-style="italic"';
                }
              }

              if (shadow) {
                if (word.superscript) {
                  svg = svg + '<text x="' + (x + 3) + '"' +
                    ' y="' + (y + 3 - fontSize * 0.4) + '"' +
                    ' font-family="' + font + '"' +
                    ' font-size="' + fontSize * 0.7 + '"' +
                    ' fill="' + shadowColor + '"' +     
                    boldStr +
                    italicStr +
                    ' style="-webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;"';
                  svg += '>' + word.text.replaceAll(' ', '&nbsp;') + '</text>';          
                } else {
                  svg = svg + '<text x="' + (x + 3) + '"' +
                    ' y="' + (y + 3) + '"' +
                    ' font-family="' + font + '"' +
                    ' font-size="' + fontSize + '"' +
                    ' fill="' + shadowColor + '"' +  
                    boldStr +
                    italicStr +                          
                    ' style="-webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;"';
                  svg += '>' + word.text.replaceAll(' ', '&nbsp;') + '</text>';     
                }

              }

              if (word.superscript) {
                svg = svg + '<text x="' + x + '"' +
                  ' y="' + (y - fontSize * 0.4) + '"' +
                  ' font-family="' + font + '"' +
                  ' font-size="' + fontSize * 0.7 + '"' +
                  ' fill="' + textColor + '"' +     
                  boldStr +
                  italicStr +
                  ' style="-webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;"';
                svg += '>' + word.text.replaceAll(' ', '&nbsp;') + '</text>';   

                if (word.showcursor) {
                    cursorX = 0;
                    if (word.text.trim() == '') {
                      cursorX = x;
                    } else {
                      cursorX = (x + word.width - (fontSize * 0.4));
                    }                  
                    svg = svg + '<rect x="' + cursorX + '"' +
                      ' id="textcursor"' + 
                      ' y="' + (y - fontSize * 0.4) + '"' +
                      ' width="' + (fontSize * 0.4 * 0.7) + '"' +
                      ' height="' + (fontSize * 0.1 * 0.7) + '"' +
                      ' fill="#000000"' + 
                      ' stroke="none"' + 
                      '></rect>';                  
                }

              } else {
                svg = svg + '<text x="' + x + '"' +
                  ' y="' + y + '"' +
                  ' font-family="' + font + '"' +
                  ' font-size="' + fontSize + '"' +
                  ' fill="' + textColor + '"' +  
                  boldStr +
                  italicStr +                          
                  ' style="-webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;"';
                svg += '>' + word.text.replaceAll(' ', '&nbsp;') + '</text>';     

                if (word.showcursor) {
                  cursorX = 0;
                  if (word.text.trim() == '') {
                    cursorX = x;
                  } else {
                    cursorX = (x + word.width - (fontSize * 0.4));
                  }
                  svg = svg + '<rect x="' + cursorX + '"' +
                    ' id="textcursor"' + 
                    ' y="' + (y) + '"' +
                    ' width="' + (fontSize * 0.4) + '"' +
                    ' height="' + (fontSize * 0.1) + '"' +
                    ' fill="#000000"' + 
                    ' stroke="none"' +                  
                    '></rect>';                  
                }
              }

              x = x + word.width;
              i++;
            }

          }

          // update help dimensions
          
          //if (help && !drawHelp) {
          if (help && helpDimensions) {

            if (this.helpBox.length >= 1) {

              let helpItem = this.helpBox[this.helpBox.length - 1];

              textline = this.prepTextLine(widget, true); 
              
              x = parseInt(widget.x) + textline.xanchoroffset;
              y = widget.y - fontSize;
              switch (align) {
                case 'center':
                  x = x - textline.controlwidth / 2;
                  break;            
                case 'right':
                  x = x - textline.controlwidth;
                  break;          
              }

              //if (this.help.hasItems) {            
              if (helpItem.foundHelp) {
                if (x < helpItem.minX) {
                  helpItem.minX = x;
                }
                if ((parseInt(widget.y)) < helpItem.minY) {
                  helpItem.minY = y;
                }              
                /*if (parseInt(widget.x) < this.help.minX) {
                  this.help.minX = x;
                }
                if ((parseInt(widget.y)) < this.help.minY) {
                  this.help.minY = y;
                }*/
              } else {
                helpItem.minX = x;
                helpItem.minY = y;
                helpItem.foundHelp = true;
                //this.help.minX = x;
                //this.help.minY = y;
                //this.help.hasItems = true;	
              }

              if ((x + textline.controlwidth - 1) > helpItem.maxX) {
                helpItem.maxX = x + textline.controlwidth - 1;
              }

              if ((y + textline.controlheight - 1) > helpItem.maxY) {
                helpItem.maxY = y + textline.controlheight - 1;
              }

              /*if ((x + textline.controlwidth - 1) > this.help.maxX) {
                this.help.maxX = x + textline.controlwidth - 1;
              }

              if ((y + textline.controlheight - 1) > this.help.maxY) {
                this.help.maxY = y + textline.controlheight - 1;
              }*/
            
            }

          }

        }

        //console.log(JSON.stringify(textline));
        return svg;

      },

      //render_textMC = function (ctx, question, widget, widgetIndex, completed, help=false, xOffset=0, yOffset=0) {	
      render_textMC(widget, index, drawHelp, helpDimensions, xOffset=0, yOffset=0) { // eslint-disable-line
        var svg = '';
        var font = 'Arial';
        var fontSize;
        var xAdjust;
        var xPos = parseInt(widget.x);
        var yPos = parseInt(widget.y);
        var i;
        var item;
        
        //var cWidth = 0;
        //var cHeight = 0;
        //var isInput = false;
        var itemStr = '';		
        //var line = '';
        var textColor;
        //var tmpWidth;
        var wanted;
        var given;

        if (Object.prototype.hasOwnProperty.call(widget, 'font')) {			
          font = widget.font;
        }
        
        
        fontSize = parseInt(widget.fontsize);
        
        //ctx.font = fontSize + 'px ' + font;		
        
        xAdjust = 0;
        switch (widget.style) {
          case 'checkboxes':
          case 'radiobuttons':
            xAdjust = fontSize;
            break;
        }
        
        i = 0;
        while (i < widget.items.length) {
          item = widget.items[i];
          
          textColor = '#000000';

          if ((this.completed) || (this.help.visible)) {
            wanted = item.correct;
            given = false;
            if (Object.prototype.hasOwnProperty.call(widget, 'userinput')) {
              given = widget.userinput[i];
            }
            if (wanted) {
              textColor = '#77bd02';
            } else if (given) {
              textColor = '#da2b02';
            } else {
              textColor = '#000000';
            }
          } 
          
          switch (widget.style) {
            
            case 'checkboxes':

              /*ctx.lineWidth = 2;
              ctx.strokeStyle = textColor;
              RoundRect(ctx, xPos + parseInt(xOffset), yPos + parseInt(yOffset) - fontSize * 0.75, fontSize * 0.75, fontSize * 0.75, 8, '', textColor);

              if (widget.hasOwnProperty('userinput')) {
                if (widget.userinput[i]) {
                  ctx.fillStyle = textColor;
                  RoundRect(ctx, xPos + parseInt(xOffset) + fontSize * 0.15, yPos + parseInt(yOffset) - fontSize * 0.75 + fontSize * 0.15, fontSize * 0.45, fontSize * 0.45, 4, textColor, '');
                }
              }*/
              alert('todo: textMC checkboxes');
              itemStr = item.text;
              
              break;

            case 'radiobuttons':

              svg = svg + '<circle cx="' + (xOffset + xPos + xAdjust - fontSize * 0.5) + '"' +
                ' cy="' + (yOffset + yPos - fontSize * 0.35) + '"' +
                ' r="' + (fontSize * 0.4) + '"' +
                ' stroke="' + textColor + '"' +
                ' stroke-width="2"' +
                ' fill="none"' +
                '></circle>';

              /*ctx.beginPath();
              ctx.arc(xPos + parseInt(xOffset) + fontSize * 0.4, yPos + parseInt(yOffset) - fontSize * 0.35, fontSize * 0.35, 0, 2 * Math.PI);
              ctx.lineWidth = 2;
              ctx.strokeStyle = textColor;
              ctx.stroke();
              ctx.closePath();*/
              
              if (Object.prototype.hasOwnProperty.call(widget, 'userinput')) {
                if (widget.userinput[i]) {

                  svg = svg + '<circle cx="' + (xOffset + xPos + xAdjust - fontSize * 0.5) + '"' +
                    ' cy="' + (yOffset + yPos - fontSize * 0.35) + '"' +
                    ' r="' + (fontSize * 0.2) + '"' +
                    ' fill="' + textColor + '"' +
                    ' stroke="none"' +
                    '></circle>';

                  //ctx.beginPath();
                  //ctx.arc(xPos + parseInt(xOffset) + fontSize * 0.4, yPos + parseInt(yOffset) - fontSize * 0.35, fontSize * 0.2, 0, 2 * Math.PI);
                  //ctx.fillStyle = textColor;
                  //ctx.fill();
                }
              }

              itemStr = item.text;
              
              break;

            case 'symbols':
              
              itemStr = String.fromCharCode(65 + i) + '. ' + item.text;
              break;
            
          }
                
          //ctx.fillStyle = textColor;
          //ctx.fillText(itemStr, xPos + xAdjust + parseInt(xOffset), yPos + parseInt(yOffset));	

          svg = svg + '<text x="' + (xOffset + xPos + xAdjust) + '"' +
            ' y="' + (yOffset + yPos) + '"' +
            ' font-family="' + font + '"' +
            ' font-size="' + fontSize + '"' +
            ' fill="' + textColor + '"' +
            ' style="cursor: pointer;"' + 
            '>' + itemStr.replaceAll(' ', '&nbsp;') + '</text>';

          yPos = yPos + fontSize + 10;	

          i++;
        }	
        
        return svg;
      },

      render_wordblock(widget, index, drawHelp, helpDimensions, xOffset=0, yOffset=0) { // eslint-disable-line
        var svg = '';
        var font = 'Arial';
        var fontSize = 32;
        var xPos = parseInt(widget.x);
        var yPos = parseInt(widget.y);		
        var i;
        var j;
        var item;		
        var cWidth = 0;
        var cHeight = 0;
        var wWidth = 0;
        var tWidth;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var tHeight;
        var ch;
        //var color;
        
        if (Object.prototype.hasOwnProperty.call(widget, 'font')) {			
          font = widget.font;
        }		
        fontSize = parseInt(widget.fontsize);		
        ctx.font = fontSize + 'px ' + font;		
        
        wWidth = parseInt(ctx.measureText('W').width);
        tHeight = fontSize * 1.45;
        item = widget.letters;
        
        if (item.length > 0) {
          cWidth = item[0].length * wWidth;
          cHeight = item.length * tHeight;
        } else {
          cWidth = 100;
          cHeight = 100;			
        }

        svg = svg + '<line x1="' + xPos + '"' +
          ' y1="' + yPos + '"' +
          ' x2="' + (xPos + cWidth) + '"' +
          ' y2="' + (yPos) + '"' +
          ' stroke="#000000"' +                  
          ' stroke-width="1"' + 
          '></line>';

        svg = svg + '<line x1="' + xPos + '"' +
          ' y1="' + yPos + '"' +
          ' x2="' + xPos + '"' +
          ' y2="' + (yPos + cHeight) + '"' +
          ' stroke="#000000"' +                  
          ' stroke-width="1"' + 
          '></line>';
				
        i = 0;
        while (i < item.length) {

          j = 0;
          while (j < item[i].length) {
            ch = item[i][j];
            tWidth = parseInt(ctx.measureText(ch).width);

            svg = svg + '<text' + 
              ' x="' + (xPos + j * wWidth + (wWidth - tWidth) / 2) + '"' +
              ' y="' + (yPos + i * tHeight + fontSize) + '"' +
              ' font-family="' + font + '"' +
              ' font-size="' + fontSize + '"' +
              ' fill="#000000"' +
              ' style="cursor: pointer; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;"' + 
              '>' + ch + '</text>';

            svg = svg + '<rect' +
              ' id="block_' + j + '_' + i + '"' +
              ' x="' + (xPos + j * wWidth) + '"' +
              ' y="' + (yPos + (i * tHeight)) + '"' +
              ' width="' + wWidth + '"' +
              ' height="' + tHeight + '"' +
              ' fill="#ffffff"' +
              ' fill-opacity="0"' +
              '></rect>';

            j++;
            
            svg = svg + '<line x1="' + (xPos + j * wWidth) + '"' +
              ' y1="' + yPos + '"' +
              ' x2="' + (xPos + j * wWidth) + '"' +
              ' y2="' + (yPos + cHeight) + '"' +
              ' stroke="#000000"' +                  
              ' stroke-width="1"' + 
              '></line>';

          }
      
          i++;

          svg = svg + '<line x1="' + xPos + '"' +
            ' y1="' + (yPos + i * tHeight) + '"' +
            ' x2="' + (xPos + j * wWidth) + '"' +
            ' y2="' + (yPos + i * tHeight) + '"' +
            ' stroke="#000000"' +                  
            ' stroke-width="1"' + 
            '></line>';          

        }

        // highlight words
        
        /*words = [];		
        if (this.help.visible) {
          words = widget.words;
        } else if (Object.prototype.hasOwnProperty.call(widget, 'state')) {
          i = 0;
          while (i < widget.state.userinput.length) {
            words.push(widget.words[widget.state.userinput[i]]);
            i++;
          }
        }

        i = 0;
        while (i < words.length) {
    
          word = words[i];
          startCol = -1;
    
          // search rows
    
          j = 0;
          while ((j < item.length) && (startCol < 0)) {
            row = item[j];
            
            foundWord = false;
            tmpCol = 0;

            while (((tmpCol + word.length - 1) < row.length) && !foundWord) {

              kStep = 0;
              foundWord = true;
              while ((kStep < word.length) && foundWord) {
                if ((tmpCol + kStep) < row.length) {
                  foundWord = (row[tmpCol + kStep] == word[kStep]);								
                } else {
                  foundWord = false;
                }
                kStep++;
              }
              
              if (!foundWord) {
                tmpCol = tmpCol + 1;
              }
              
            }
      
            if (foundWord) {
              startCol = tmpCol;
              startRow = j;
              endCol = tmpCol + word.length - 1;
              endRow = j;
            }
      
            j++;
          }
    
          // search columns
    
          if (startCol < 0) {

            j = 0;
            while (((j + word.length - 1) < item.length) && (startCol < 0)) {
              row = item[j];
              
              foundWord = false;
              tmpCol = 0;
              while ((tmpCol < row.length) && !foundWord) {
              
                kStep = 0;
                foundWord = true;
                while ((kStep < word.length) && foundWord) {
                  row = item[j + kStep];
                  if (tmpCol < row.length) {
                    foundWord = (row[tmpCol] == word[kStep]);
                  } else {
                    foundWord = false;
                  }
                  kStep++;
                }
                
                if (!foundWord) {
                  tmpCol = tmpCol + 1;
                }
              }
              
              if (foundWord) {
                startCol = tmpCol;
                startRow = j;
                endCol = tmpCol;
                endRow = j + word.length - 1;
              }
              
              j++;
            }

          }
    
          if (startCol >= 0) {

            switch (i % 4) {
              case 0:
                color = '#007700';
                break;
              case 1:
                color = '#770000';					
                break;
              case 2:
                color = '#000099';	
                break;
              case 3:
                color = '#999900';
                break;
            }

            svg = svg + '<rect x="' + (xPos + (startCol * wWidth)) + '"' +
              ' y="' + (yPos + (startRow * tHeight)) + '"' +
              ' width="' + ((endCol - startCol + 1) * wWidth) + '"' +
              ' height="' + ((endRow - startRow + 1) * tHeight) + '"' +
              ' y="' + (this.help.y - 10) + '"' +
              ' fill="' + color + '"' +
              ' fill-opacity="0.5"' +
              ' stroke="' + color + '"' +
              ' stroke-width="1"' +
              ' rx="8"' +
              ' ry="8"' +
              '></rect>';

          }
    
          i++;
        }
        
        // show current user selection
        
        if (Object.prototype.hasOwnProperty.call(widget, 'state')) {
          
          startCol = widget.state.startcol;
          startRow = widget.state.startrow;
          endCol = widget.state.endcol;
          endRow = widget.state.endrow;

          if (startCol > endCol) {
            tmpInt = startCol;
            startCol = endCol;
            endCol = tmpInt;
          }

          if (startRow > endRow) {
            tmpInt = startRow;
            startRow = endRow;
            endRow = tmpInt;
          }
          
          if (startCol >= 0) {
            
            ctx.strokeStyle = '#444444';
            ctx.fillStyle = 'rgba(68, 68, 68, 0.5)';					
            
            if ((startCol == endCol) || (startRow == endRow)) {

              svg = svg + '<rect x="' + (xPos + (startCol * wWidth)) + '"' +
                ' y="' + (yPos + (startRow * tHeight)) + '"' +
                ' width="' + ((endCol - startCol + 1) * wWidth) + '"' +
                ' height="' + ((endRow - startRow + 1) * tHeight) + '"' +
                ' fill="#444444"' +
                ' fill-opacity="0.5"' +
                ' stroke="#444444"' +
                ' stroke-width="1"' +
                ' rx="8"' +
                ' ry="8"' +
                '></rect>';

            } else {

              svg = svg + '<rect x="' + (xPos + (startCol * wWidth)) + '"' +
                ' y="' + (yPos + (startRow * tHeight)) + '"' +
                ' width="' + wWidth + '"' +
                ' height="' + tHeight + '"' +
                ' fill="#444444"' +
                ' fill-opacity="0.5"' +
                ' stroke="#444444"' +
                ' stroke-width="1"' +
                ' rx="8"' +
                ' ry="8"' +
                '></rect>';

            }
            
          }
          
        }       */

        return svg;
      },

      replaceAllCI(sourceStr, findStr, withStr) {
        var result;
        var esc = findStr.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // eslint-disable-line
        var reg = new RegExp(esc, 'ig');
        result = sourceStr.replace(reg, withStr);
        return result;
      },

      replayAudio() {
        this.audioQueue = [];
        if (this.currentAudioClip != null) {
          this.currentAudioClip.pause();
          this.currentAudioClip = null;
        }
        this.audioQueue.push(this.audioQueueSaved[0]);
        this.playAudioQueue();
      },

      shuffle_dragText(widget) {
        var a;
        var b;
        var tmpStr;
        
        if (Object.prototype.hasOwnProperty.call(widget, 'shuffle')) {
          if (widget.shuffle) {
            
            a = Math.floor(Math.random() * widget.items.length);
            b = Math.floor(Math.random() * widget.items.length);
            if (a != b) {
              
              tmpStr = widget.items[a].text;
              widget.items[a].text = widget.items[b].text;
              widget.items[b].text = tmpStr;
              
              if (widget.answerindex == a) {
                widget.answerindex = b;
              } else if (widget.answerindex == b) {
                widget.answerindex = a;
              }
            
            }				
            
            widget.shuffle = false;
          }
        }
      },

      shuffle_textMC(widget) {
        var a;
        var b;
        var tmpItem;
        var i = 0;

        if (Object.prototype.hasOwnProperty.call(widget, 'shuffle')) {
          if (widget.shuffle) {
        
            i = 0
            while (i < (widget.items.length * widget.items.length)) {

              a = Math.floor(Math.random() * widget.items.length);
              b = Math.floor(Math.random() * widget.items.length);
              if (a != b) {
                
                tmpItem = widget.items[a];
                widget.items[a] = widget.items[b];
                widget.items[b] = tmpItem;

              }		

              i++;
            }
            widget.shuffle = false;
          }
        }
      },

      shuffleItems() {
        var i;
        var widget;
        
        i = 0;
        while (i < this.question.widgets.length) {
          widget = this.question.widgets[i];
          switch (widget.type) {
            case 'dragtext':
              this.shuffle_dragText(widget);					
              break;
            case 'textmc':
              this.shuffle_textMC(widget);					
              break;					
          }
          i++;
        }
        
      },

      substituteName(object, name) {
        var i;
        var property;

        if (typeof(object) == 'object') {

          if (Array.isArray(object)) {
            i = 0;
            while (i < object.length) {
              if (typeof(object[i]) == 'object') {
                this.substituteName(object[i], name) 
              } else if (typeof(object[i]) == 'string') {
                object[i] = this.replaceAllCI(object[i], '[name]', name);
              }
              i++;
            }					
          } else {					
            for (property in object) {
              if (typeof(object[property]) == 'object') {
                this.substituteName(object[property], name);
              } else if (typeof(object[property]) == 'string') {
                object[property] = this.replaceAllCI(object[property], '[name]', name);
              }
            }
          }
            
        }
      },

      substitutePhrases(object) {
        var i;
        var key;
        var property;
        
        if (typeof(object) == 'object') {

          if (Array.isArray(object)) {
            i = 0;
            while (i < object.length) {
              if (typeof(object[i]) == 'object') {
                this.substitutePhrases(object[i]) 
              } else if (typeof(object[i]) == 'string') {	
                for (key in this.phrases) {
                  object[i] = this.replaceAllCI(object[i], '[' + key + ']', this.phrases[key]);
                }							
              }
              i++;
            }					
          } else {					
            for (property in object) {
              if (typeof(object[property]) == 'object') {
                this.substitutePhrases(object[property]);
              } else if (typeof(object[property]) == 'string') {
                for (key in this.phrases) {
                  object[property] = this.replaceAllCI(object[property], '[' + key + ']', this.phrases[key]);
                }						
              }
                
            }
          }
            
        }
      },

      substituteSymbols(object) {
        var i;
        var key;
        var property;

        if (typeof(object) == 'object') {

          if (Array.isArray(object)) {
            i = 0;
            while (i < object.length) {
              if (typeof(object[i]) == 'object') {
                this.substituteSymbols(object[i], this.symbols) 
              } else if (typeof(object[i]) == 'string') {		
                for (key in this.symbols) {
                  object[i] = this.replaceAllCI(object[i], '{' + key + '}', this.symbols[key]);
                }	
              }
              i++;
            }					
          } else {					
            for (property in object) {
              if (typeof(object[property]) == 'object') {
                this.substituteSymbols(object[property], this.symbols);
              } else if (typeof(object[property]) == 'string') {
                for (key in this.symbols) {
                  object[property] = this.replaceAllCI(object[property], '{' + key + '}', this.symbols[key]);
                }						
              }
            }
          }

        }        
      },

      toggleHelp() {
        this.help.visible = !this.help.visible;
        if (this.help.visible && !this.completed) {
          this.usedHelp = true;
        }
        this.renderQuestion();
      },

      runLogic(logic) {
        let symbols = {};
        let i;
        let j;
        let symbolName = '';
        let result = '0';
        let excludeValues;
        //let a;
        //let b;

        i = 0;
        while (i < logic.length) {

          let ins = logic[i];
          switch (ins.instruction) {

            // ***** createexp ***************************

            case 'createexp':
              symbolName = ins.var.toLowerCase(); 
              result = this.evaluate(symbols, ins.exp);
              symbols[symbolName] = result;              
              break;

            // ***** defvar ***************************

            case 'defvar':
              symbolName = ins.var.toLowerCase(); 
              var min = parseInt(this.parseValue(symbols, ins.min));
              var max = parseInt(this.parseValue(symbols, ins.max));

              if (ins.exclude.length > 0) {

                if (ins.exclude.length >= (max - min + 1)) {
                  result = min;
                } else {
                  excludeValues = [];
                  j = 0;
                  while (j < ins.exclude.length) {
                    excludeValues.push(parseInt(this.parseValue(symbols, ins.exclude[j])));
                    j++;
                  }
                  do {
                    result = Math.floor(Math.random() * (max - min + 1)) + min;
                  } while (excludeValues.indexOf(result) >= 0); 
                }

              } else {
                result = Math.floor(Math.random() * (max - min + 1)) + min;
              }

              symbols[symbolName] = result;              
              break;

            default:
              alert('Unknown instruction: ' + ins.instruction);
          }

          i++;
        }

        return symbols;

      },

      evaluate(symbols, item) {

        var result;
        var tmpA;
        var tmpB;
        var varName;

        if (Object.prototype.toString.call(item) === '[object Object]') {

          switch (item['*invoke']) {
            
            case 'add':
              tmpA = parseFloat(this.evaluate(symbols, item['parms'][0]));
              tmpB = parseFloat(this.evaluate(symbols, item['parms'][1]));
              result = tmpA + tmpB;
              break;

            case 'div':
              tmpA = parseFloat(this.evaluate(symbols, item['parms'][0]));
              tmpB = parseFloat(this.evaluate(symbols, item['parms'][1]));
              result = tmpA / tmpB;
              break;

            case 'idiv':
              tmpA = parseInt(this.evaluate(symbols, item['parms'][0]));
              tmpB = parseInt(this.evaluate(symbols, item['parms'][1]));
              result = Math.floor(tmpA / tmpB);
              break;

            case 'lookup':
              varName = item['source'][0]['key'];
              if (Object.prototype.hasOwnProperty.call(symbols, varName.toLowerCase())) {
                result = symbols[varName.toLowerCase()];
              }
              break;

            case 'mod':
              tmpA = parseInt(this.evaluate(symbols, item['parms'][0]));
              tmpB = parseInt(this.evaluate(symbols, item['parms'][1]));
              result = tmpA % tmpB;
              break;

            case 'mul':
              tmpA = parseFloat(this.evaluate(symbols, item['parms'][0]));
              tmpB = parseFloat(this.evaluate(symbols, item['parms'][1]));
              result = tmpA * tmpB;
              break;

            case 'sub':
              tmpA = parseFloat(this.evaluate(symbols, item['parms'][0]));
              tmpB = parseFloat(this.evaluate(symbols, item['parms'][1]));
              result = tmpA - tmpB;
              break;

            case 'call':
              if (item.path.length == 1) {
                if (Object.prototype.hasOwnProperty.call(item.path[0], 'key')) {
                  switch (item.path[0].key) {
                    case 'ceil':
                      tmpA = parseFloat(this.evaluate(symbols, item['parms'][0]));
                      result = Math.ceil(tmpA);
                      break;                    
                    case 'cos':
                      tmpA = parseFloat(this.evaluate(symbols, item['parms'][0]));
                      result = Math.cos(tmpA * (3.1415926535 / 180));
                      break;      
                    case 'floor':
                      tmpA = parseFloat(this.evaluate(symbols, item['parms'][0]));
                      result = Math.floor(tmpA);
                      break;      
                    case 'round':
                      tmpA = parseFloat(this.evaluate(symbols, item['parms'][0]));
                      result = Math.round(tmpA);
                      break;                                                        
                    case 'sin':
                      tmpA = parseFloat(this.evaluate(symbols, item['parms'][0]));
                      result = Math.sin(tmpA * (3.1415926535 / 180));
                      break;
                    case 'tan':
                      tmpA = parseFloat(this.evaluate(symbols, item['parms'][0]));
                      result = Math.tan(tmpA * (3.1415926535 / 180));
                      break;                        
                    default:
                      alert('Unknown function: ' + item.path[0].key);
                  }
                }                
              }
              break

            default:
              alert('Unknown eval: ' + item['*invoke']);
          }

        } else {
          result = item.toString();
        }

        return result;

      },

      parseValue(symbols, value ) {
        var result = '0';
        if (!isNaN(value)) {
          result = value;
        } else {
          if (Object.prototype.hasOwnProperty.call(symbols, value.toLowerCase())) {
            result = symbols[value.toLowerCase()];
          }
        }
        return result;
      }

    }

  }
</script>

<style scoped>
</style>