var memory = {};
var localMemory = [];
//var functions = {};

export default { 

    callFunction(functionName, parms) {
        var result;

        switch (functionName) {
            case 'chr':
                result = String.fromCharCode(this.evaluate(parms[0]));
                break;
            default:
                alert('Unknown function: ' + functionName)  
        }

        return result;
    },

    callMethod(path, parms) {
        var result;
        var rootName = path[0].key.toLowerCase();
        var methodName = path[path.length - 1].key.toLowerCase();
        switch (rootName) {

            case 'random':
                switch (methodName) {
                    case 'integer':
                        result = Math.floor(Math.random() * (parms[1] - parms[0] + 1)) + parms[0];
                        break;
                    default:
                        alert('Unknown random method: ' + methodName);
                }        
                break;

            default:
                alert('TODO: invoke method on ' + rootName)
        }
        return result;
    },

    evaluate(value) {
        var result;

        if (typeof value === 'object') {
            
            switch (value['*invoke']) {
                case 'add':
                    result = this.evaluate(value.parms[0]) + this.evaluate(value.parms[1]);                    
                    break;

                case 'and':
                    result = this.evaluate(value.parms[0]) && this.evaluate(value.parms[1]);
                    break;

                case 'call':
                    if (value.path.length == 1) {
                        // one item implies a function is being called
                        result = this.callFunction(value.path[0].key, value.parms)
                    } else {
                        // more than one item implies that a method is being called
                        result = this.callMethod(value.path, value.parms);
                    }
                    break;

                case 'div':
                    result = this.evaluate(value.parms[0]) / this.evaluate(value.parms[1]);                    
                    break;

                case 'eq':
                    result = this.evaluate(value.parms[0]) == this.evaluate(value.parms[1]);
                    break;

                case 'gt':
                    result = this.evaluate(value.parms[0]) > this.evaluate(value.parms[1]);
                    break;

                case 'gteq':
                    result = this.evaluate(value.parms[0]) >= this.evaluate(value.parms[1]);
                    break;

                case 'lookup':
                    result = this.getValue(value.source);                    
                    break;     

                case 'lt':
                    result = this.evaluate(value.parms[0]) < this.evaluate(value.parms[1]);
                    break;

                case 'lteq':
                    result = this.evaluate(value.parms[0]) <= this.evaluate(value.parms[1]);
                    break;

                case 'mul':
                    result = this.evaluate(value.parms[0]) * this.evaluate(value.parms[1]);                    
                    break;                    

                case 'pow':
                    result = Math.pow(this.evaluate(value.parms[0]), this.evaluate(value.parms[1]));
                    break;        

                case 'neq':
                    result = this.evaluate(value.parms[0]) != this.evaluate(value.parms[1]);
                    break;

                case 'sub':
                    result = this.evaluate(value.parms[0]) - this.evaluate(value.parms[1]);                    
                    break;

                default:
                    alert('Unknown expression: ' + value['*invoke']);                
            }

        } else {

            result = value;

        }
        //alert(JSON.stringify(value));
        
        return result;
    },

    execute_assign(statement) {
        var value = this.evaluate(statement.value);
        this.setValue(statement.target, value);
    },

    execute_if(statement) {
        var i;
        var foundMatch;
        var match;

        i = 0;
        foundMatch = false;
        while ((i < statement.match.length) && !foundMatch) {
            match = statement.match[i]
            foundMatch = this.evaluate(match.expression);
            if (foundMatch) {
                this.executeBlock(match.statements);
            } else {
                i++;
            }
        }

        if (!foundMatch) {
            if (Object.prototype.hasOwnProperty.call(statement, 'else')) {
                this.executeBlock(statement.else);
            }
        }

    },

    execute_until(statement) {

        do {
            this.executeBlock(statement.statements);
        } while (!this.evaluate(statement.condition));

        /*var i;
        var foundMatch;
        var match;

        i = 0;
        foundMatch = false;
        while ((i < statement.match.length) && !foundMatch) {
            match = statement.match[i]
            foundMatch = this.evaluate(match.expression);
            if (foundMatch) {
                this.executeBlock(match.statements);
            } else {
                i++;
            }
        }

        if (!foundMatch) {
            if (Object.prototype.hasOwnProperty.call(statement, 'else')) {
                this.executeBlock(statement.else);
            }
        }*/

    },

    executeBlock(block) {
        var i = 0;
        while (i < block.length) {
            this.executeStatement(block[i]);
            i++;
        }
    },

    executeStatement(statement) {
        
        switch (statement['*invoke']) {
            case 'assign':
                this.execute_assign(statement);
                break;
            case 'if':
                this.execute_if(statement);
                break;                
            case 'until':
                this.execute_until(statement);
                break;
            default:
                alert('Unknown statement: ' + statement['*invoke']);
        }
        //alert(JSON.stringify(statement));
    },

    getValue(path) {
        var result;
        //var scope = 'local';
        var i = 0;
        var key;
        var sourceItem;
        //var index;

        if (Object.prototype.hasOwnProperty.call(path[0],'key')) {
            key= path[0].key.toLowerCase();
            switch (key) {
                case 'memory':                    
                    sourceItem = memory;
                    //scope = 'memory';
                    i = 1;
                    break;
                default:
                    sourceItem = localMemory[localMemory.length - 1];
            }
        }

        if (i >= path.length) {

            result = sourceItem;

        } else {

            while (i < path.length) {

                if (Object.prototype.hasOwnProperty.call(path[i], 'key')) {
                    // update key
                    key = this.evaluate(path[i].key);

                    if ((i >= (path.length - 1)) && (key == 'count')) {
                        alert('todo: return number of array elements, or number of object keys')
                    } else {

                        if (!Array.isArray(sourceItem)) {

                            if (i >= (path.length - 1)) {
                                result = sourceItem[key];
                            } else {
                                sourceItem = sourceItem[key];
                            }

                        } else {
                            alert('[Synergy.setValue] Cannot read key values from an array');
                        }

                    }

                    //targetItem[key] = value;                    
                
                } else if (Object.prototype.hasOwnProperty.call(path[i], 'index')) {
                    alert('todo: traverse index')
                    // update index
                    //index = this.evaluate(path[i].index);
                    //targetItem[index] = value;
                } else {
                    alert('[Synergy.setValue] Unknown lookup type');
                }

                /*if (i == (path.length - 1)) {
                    if (Object.prototype.hasOwnProperty.call(path[i], 'key')) {
                        // update key
                        key = this.evaluate(path[i].key);
                        targetItem[key] = value;                    
                    } else {
                        // update index
                        index = this.evaluate(path[i].index);
                        targetItem[index] = value;
                    }
                } else {
                    alert('TODO: iterate path');
                    i = path.length;
                }*/
                i++;
            }        

        }

        return result;
    },

    run(script) {
        memory = {}; // clear memory
        
        //functions = script.functions; // set functions

        localMemory.push({}); // create local scope

        if (script) {
            this.executeBlock(script.statements);
        }
        
        localMemory.pop(); // remove local scope

        return memory;
    },

    setValue(path, value) {
        var scope = 'local';
        var i = 0;
        var key;
        var targetItem;
        var index;

        if (Object.prototype.hasOwnProperty.call(path[0],'key')) {
            key= path[0].key.toLowerCase();
            switch (key) {
                case 'memory':                    
                    if (path.length == 1) {
                        memory = value;
                    } else {
                        targetItem = memory;
                        scope = 'memory';
                    }
                    i = 1;
                    break;
                default:
                    targetItem = localMemory[localMemory.length - 1];                    
            }
        }

        while (i < path.length) {
            if (i == (path.length - 1)) {
                if (Object.prototype.hasOwnProperty.call(path[i], 'key')) {
                    // update key
                    key = this.evaluate(path[i].key);
                    targetItem[key] = value;
                    memory[key] = value;                
                } else {
                    // update index
                    index = this.evaluate(path[i].index);
                    targetItem[index] = value;
                    memory[index] = value;
                }
            } else {
                alert('TODO: iterate path');
                i = path.length;
            }
            i++;
        }

        if (scope == 'storage') {
            alert('TODO: write local storage');
        }
        
    }

};
