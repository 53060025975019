export default { 
  createKey(key, x, y, font) {
    var svg;
  
      svg = '<g>';

        svg = svg + '<rect x="' + x + '"' +
          ' y="' + y + '"' +
          ' width="60"' +
          ' height="75"' +
          ' fill="#d3d6da"' +
          ' rx="4"' +
          ' ry="4"' +
          ' style="cursor: pointer;"' + 
          '></rect>';

          svg = svg + '<text x="' + (x + 30) + '"' +
            ' y="' + (y + 50) + '"' +
            ' font-family="' + font + '"' +
            ' font-size="42"' +
            ' fill="#000000"' +
            ' text-anchor="middle"' +
            ' style="cursor: pointer;"' + 
            '>' + key + '</text>';

      svg = svg + '</g>';		
  
      return svg;
  },

  setKeyCase(key, uppercase) {
    var result = key;
    if (uppercase) {
      if (key == '⇧') {
        result = '⇩';
      } else {
        result = key.toUpperCase();
      }      
    }
    return result;
  },

  createKeyboard_alpha1(x, y, font, uppercase) {
    var svg;


    // q
    svg += this.createKey(this.setKeyCase('q', uppercase), x, y, font);
    // w
    svg += this.createKey(this.setKeyCase('w', uppercase), x + 70, y, font);
    // e
    svg += this.createKey(this.setKeyCase('e', uppercase), x + 140, y, font);
    // r
    svg += this.createKey(this.setKeyCase('r', uppercase), x + 210, y, font);
    // t
    svg += this.createKey(this.setKeyCase('t', uppercase), x + 280, y, font);
    // y
    svg += this.createKey(this.setKeyCase('y', uppercase), x + 350, y, font);
    // u
    svg += this.createKey(this.setKeyCase('u', uppercase), x + 420, y, font);
    // i
    svg += this.createKey(this.setKeyCase('i', uppercase), x + 490, y, font);
    // o
    svg += this.createKey(this.setKeyCase('o', uppercase), x + 560, y, font);
    // p
    svg += this.createKey(this.setKeyCase('p', uppercase), x + 630, y, font);

    // a
    svg += this.createKey(this.setKeyCase('a', uppercase), x + 35, y + 85, font);
    // s
    svg += this.createKey(this.setKeyCase('s', uppercase), x + 105, y + 85, font);
    // d
    svg += this.createKey(this.setKeyCase('d', uppercase), x + 175, y + 85, font);
    // f
    svg += this.createKey(this.setKeyCase('f', uppercase), x + 245, y + 85, font);
    // g
    svg += this.createKey(this.setKeyCase('g', uppercase), x + 315, y + 85, font);
    // h
    svg += this.createKey(this.setKeyCase('h', uppercase), x + 385, y + 85, font);
    // j
    svg += this.createKey(this.setKeyCase('j', uppercase), x + 455, y + 85, font);
    // k
    svg += this.createKey(this.setKeyCase('k', uppercase), x + 525, y + 85, font);
    // l
    svg += this.createKey(this.setKeyCase('l', uppercase), x + 595, y + 85, font);

    // ⇧
    svg += this.createKey(this.setKeyCase('⇧', uppercase), x, y + 170, font);	    
    // z
    svg += this.createKey(this.setKeyCase('z', uppercase), x + 70, y + 170, font);
    // x
    svg += this.createKey(this.setKeyCase('x', uppercase), x + 140, y + 170, font);
    // c
    svg += this.createKey(this.setKeyCase('c', uppercase), x + 210, y + 170, font);
    // v
    svg += this.createKey(this.setKeyCase('v', uppercase), x + 280, y + 170, font);
    // b
    svg += this.createKey(this.setKeyCase('b', uppercase), x + 350, y + 170, font);
    // n
    svg += this.createKey(this.setKeyCase('n', uppercase), x + 420, y + 170, font);
    // m
    svg += this.createKey(this.setKeyCase('m', uppercase), x + 490, y + 170, font);
    // ⌫
    svg += this.createKey('⌫', x + 560, y + 170, font);	

    return svg;
  },

  createKeyboard_alphanumeric1(x, y, font, uppercase) {
    var svg;
  
    // q
    svg += this.createKey(this.setKeyCase('q', uppercase), x, y + 85, font);
    // w
    svg += this.createKey(this.setKeyCase('w', uppercase), x + 70, y + 85, font);
    // e
    svg += this.createKey(this.setKeyCase('e', uppercase), x + 140, y + 85, font);
    // r
    svg += this.createKey(this.setKeyCase('r', uppercase), x + 210, y + 85, font);
    // t
    svg += this.createKey(this.setKeyCase('t', uppercase), x + 280, y + 85, font);
    // y
    svg += this.createKey(this.setKeyCase('y', uppercase), x + 350, y + 85, font);
    // u
    svg += this.createKey(this.setKeyCase('u', uppercase), x + 420, y + 85, font);
    // i
    svg += this.createKey(this.setKeyCase('i', uppercase), x + 490, y + 85, font);
    // o
    svg += this.createKey(this.setKeyCase('o', uppercase), x + 560, y + 85, font);
    // p
    svg += this.createKey(this.setKeyCase('p', uppercase), x + 630, y + 85, font);

    // a
    svg += this.createKey(this.setKeyCase('a', uppercase), x + 35, y + 170, font);
    // s
    svg += this.createKey(this.setKeyCase('s', uppercase), x + 105, y + 170, font);
    // d
    svg += this.createKey(this.setKeyCase('d', uppercase), x + 175, y + 170, font);
    // f
    svg += this.createKey(this.setKeyCase('f', uppercase), x + 245, y + 170, font);
    // g
    svg += this.createKey(this.setKeyCase('g', uppercase), x + 315, y + 170, font);
    // h
    svg += this.createKey(this.setKeyCase('h', uppercase), x + 385, y + 170, font);
    // j
    svg += this.createKey(this.setKeyCase('j', uppercase), x + 455, y + 170, font);
    // k
    svg += this.createKey(this.setKeyCase('k', uppercase), x + 525, y + 170, font);
    // l
    svg += this.createKey(this.setKeyCase('l', uppercase), x + 595, y + 170, font);

    // ⇧
    svg += this.createKey(this.setKeyCase('⇧', uppercase), x, y + 255, font);
    // z
    svg += this.createKey(this.setKeyCase('z', uppercase), x + 70, y + 255, font);
    // x
    svg += this.createKey(this.setKeyCase('x', uppercase), x + 140, y + 255, font);
    // c
    svg += this.createKey(this.setKeyCase('c', uppercase), x + 210, y + 255, font);
    // v
    svg += this.createKey(this.setKeyCase('v', uppercase), x + 280, y + 255, font);
    // b
    svg += this.createKey(this.setKeyCase('b', uppercase), x + 350, y + 255, font);
    // n
    svg += this.createKey(this.setKeyCase('n', uppercase), x + 420, y + 255, font);
    // m
    svg += this.createKey(this.setKeyCase('m', uppercase), x + 490, y + 255, font);

    // 7
    svg += this.createKey('7', x + 720, y, font);
    // 8
    svg += this.createKey('8', x + 790, y, font);
    // 9
    svg += this.createKey('9', x + 860, y, font);

    // 4
    svg += this.createKey('4', x + 720, y + 85, font);
    // 5
    svg += this.createKey('5', x + 790, y + 85, font);
    // 6
    svg += this.createKey('6', x + 860, y + 85, font);

    // 1
    svg += this.createKey('1', x + 720, y + 170, font);
    // 2
    svg += this.createKey('2', x + 790, y + 170, font);
    // 3
    svg += this.createKey('3', x + 860, y + 170, font);

    // 0
    svg += this.createKey('0', x + 720, y + 255, font);
    // .
    svg += this.createKey('.', x + 790, y + 255, font);
    // ⌫
    svg += this.createKey('⌫', x + 860, y + 255, font);

    return svg;
  },

  createKeyboard_numeric1(x, y, font) {
    var svg;
  
    // 7
    svg += this.createKey('7', x, y, font);
    // 8
    svg += this.createKey('8', x + 70, y, font);
    // 9
    svg += this.createKey('9', x + 140, y, font);

    // 4
    svg += this.createKey('4', x, y + 85, font);
    // 5
    svg += this.createKey('5', x + 70, y + 85, font);
    // 6
    svg += this.createKey('6', x + 140, y + 85, font);

    // 1
    svg += this.createKey('1', x , y + 170, font);
    // 2
    svg += this.createKey('2', x + 70, y + 170, font);
    // 3
    svg += this.createKey('3', x + 140, y + 170, font);

    // 0
    svg += this.createKey('0', x, y + 255, font);
    // .
    svg += this.createKey('.', x + 70, y + 255, font);
    // ⌫
    svg += this.createKey('⌫', x + 140, y + 255, font);	

    return svg;
  },

  keyAt_alpha1(x, y) {
    var result = '';
    var index;

    if ((y >= 0) && (y < 75)) {
      
      // row 1
      
      index = Math.floor(x / 70);

      switch (index) {
        case 0: result = 'q'; break;
        case 1: result = 'w'; break;
        case 2: result = 'e'; break;
        case 3: result = 'r'; break;
        case 4: result = 't'; break;
        case 5: result = 'y'; break;
        case 6: result = 'u'; break;
        case 7: result = 'i'; break;
        case 8: result = 'o'; break;
        case 9: result = 'p'; break;
      }

    } else if ((y >= 85) && (y < 160)) {

      // row 2

      index = Math.floor((x - 35) / 70);

      switch (index) {
        case 0: result = 'a'; break;
        case 1: result = 's'; break;
        case 2: result = 'd'; break;
        case 3: result = 'f'; break;
        case 4: result = 'g'; break;
        case 5: result = 'h'; break;
        case 6: result = 'j'; break;
        case 7: result = 'k'; break;
        case 8: result = 'l'; break;
      }

    } else if ((y >= 170) && (y < 245)) {

      // row 2

      index = Math.floor(x / 70);

      switch (index) {
        case 0: result = 'CASE'; break;
        case 1: result = 'z'; break;
        case 2: result = 'x'; break;
        case 3: result = 'c'; break;
        case 4: result = 'v'; break;
        case 5: result = 'b'; break;
        case 6: result = 'n'; break;
        case 7: result = 'm'; break;
        case 8: result = 'BACKSPACE'; break;
      }

    }
    return result;
  },

  keyAt_alphanumeric1(x, y) {
    var result = '';
    var index;

    if ((y >= 0) && (y < 75)) {

      if (x >= 720) {

        index = Math.floor((x - 720) / 70);

        switch (index) {
          case 0: result = '7'; break;
          case 1: result = '8'; break;
          case 2: result = '9'; break;
        }

      }

    } if ((y >= 85) && (y < 160)) {
      
      // row 2
      
      if (x >= 720) {

        index = Math.floor((x - 720) / 70);

        switch (index) {
          case 0: result = '4'; break;
          case 1: result = '5'; break;
          case 2: result = '6'; break;
        }

      } else {

        index = Math.floor(x / 70);

        switch (index) {
          case 0: result = 'q'; break;
          case 1: result = 'w'; break;
          case 2: result = 'e'; break;
          case 3: result = 'r'; break;
          case 4: result = 't'; break;
          case 5: result = 'y'; break;
          case 6: result = 'u'; break;
          case 7: result = 'i'; break;
          case 8: result = 'o'; break;
          case 9: result = 'p'; break;
        }
      
      }

    } else if ((y >= 170) && (y < 245)) {

      // row 3

      if (x >= 720) {

        index = Math.floor((x - 720) / 70);

        switch (index) {
          case 0: result = '1'; break;
          case 1: result = '2'; break;
          case 2: result = '3'; break;
        }

      } else {

        index = Math.floor((x - 35) / 70);

        switch (index) {
          case 0: result = 'a'; break;
          case 1: result = 's'; break;
          case 2: result = 'd'; break;
          case 3: result = 'f'; break;
          case 4: result = 'g'; break;
          case 5: result = 'h'; break;
          case 6: result = 'j'; break;
          case 7: result = 'k'; break;
          case 8: result = 'l'; break;
        }

      }

    } else if ((y >= 255) && (y < 330)) {

      // row 4

      if (x >= 720) {
        
        index = Math.floor((x - 720) / 70);

        switch (index) {
          case 0: result = '0'; break;
          case 1: result = '.'; break;
          case 2: result = 'BACKSPACE'; break;
        }        

      } else {

        index = Math.floor(x / 70);

        switch (index) {
          case 0: result = 'CASE'; break;
          case 1: result = 'z'; break;
          case 2: result = 'x'; break;
          case 3: result = 'c'; break;
          case 4: result = 'v'; break;
          case 5: result = 'b'; break;
          case 6: result = 'n'; break;
          case 7: result = 'm'; break;
        }

      }

    }
    return result;
  },

  keyAt_numeric1(x, y) {
    var result = '';
    var index;

    if ((y >= 0) && (y < 75)) {
      
      // row 1
      
      index = Math.floor(x / 70);

      switch (index) {
        case 0: result = '7'; break;
        case 1: result = '8'; break;
        case 2: result = '9'; break;
      }

    } else if ((y >= 85) && (y < 160)) {

      // row 2

      index = Math.floor(x / 70);

      switch (index) {
        case 0: result = '4'; break;
        case 1: result = '5'; break;
        case 2: result = '6'; break;
      }

    } else if ((y >= 170) && (y < 245)) {

      // row 3

      index = Math.floor(x / 70);

      switch (index) {
        case 0: result = '1'; break;
        case 1: result = '2'; break;
        case 2: result = '3'; break;
      }

    } else if ((y >= 255) && (y < 330)) {

      // row 4

      index = Math.floor(x / 70);

      switch (index) {
        case 0: result = '0'; break;
        case 1: result = '.'; break;
        case 2: result = 'BACKSPACE'; break;
      }

    }

    return result;
  }  

};
